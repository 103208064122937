/**
 * Article Tables and Figures
 * Article styles for browsers that pass CTM
 */

.c-article-section__figure {
	border: 2px solid map-get($article--grey-palette, 'grey-6');
	padding: 20px 10px;
	max-width: 100%;
	margin-bottom: $article--vertical-spacing-large;
	clear: both;
}

.c-article-section__figure--1-border {
	border-width: 1px;
	display: inline-block;
	position: relative;
	padding: 0;
	margin: 0;
}

.c-article-section__figure--1-border-image {
	float: left;
	max-width: 100%;
}

.c-article-section__figure--no-border {
	border: none;
	padding: 20px 0;
}

.c-article-section__figure-caption {
	margin-bottom: $article--vertical-spacing-small;
	display: block;
	word-break: break-word;
}

.c-article-section__figure-1-border-caption {
	border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
	color: map-get($article--grey-palette, 'grey-3');
	font-size: map-get($article--font-sizes, 'font-size-1');
	margin: 0;
	padding: $article--vertical-spacing-small 0;
	max-width: 100%;
}

.c-article-section__figure-credit {
	font-size: map-get($article--font-sizes, 'font-size-1');
	font-family: $context--font-family-sans;
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-section__figure-credit-right {
	text-align: right;
}

.c-article-section__figure-content {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-section__figure-item {
	max-width: 100%;
}

.c-article-section__figure-link {
	max-width: 100%;
	display: block;
	margin: 0 0 $article--vertical-spacing-medium;
	padding: 0;
}

.c-article-section__figure .video {
	margin: 0 0 $article--vertical-spacing-medium;
}

.c-article-section__figure-item img {
	display: block;
	margin: 0 auto;
}

.c-article-section__figure-description {
	font-size: map-get($article--font-sizes, 'font-size-3');
}

.c-article-section__figure-description > * {
	margin-bottom: 0;
}

.c-article-figure-content {
	clear: both;
}

.c-article-figure-content-image {
	margin-bottom: $article--vertical-spacing-medium;
	text-align: center;
}

.c-article-figure-content-image:before,
.c-article-figure-content-image:after {
	content: ' ';
	display: table;
}

.c-article-figure-content-image:after {
	clear: both;
}

.c-article-figure-content-image-inline {
	display: inline-block;
}

.c-article-figure-description {
	font-family: $article--font-family;
	margin: 0 0 $article--vertical-spacing-large 0;
}

.c-article-figure-description > * {
	margin-bottom: 0;
}

.c-article-figure-description > * + * {
	margin-top: $article--vertical-spacing-large;
}

.c-article-figure-button-container,
.c-article-metrics__button-container {
	text-align: right;
}

.c-article-table {
	border: 2px solid map-get($article--grey-palette, 'grey-6');
	clear: both;
	padding: 20px 10px;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-table-image {
	text-align: center;
}

.c-article-table__figcaption,
.c-article-satellite-title {
	line-height: 1.4;
	margin-bottom: $article--vertical-spacing-medium;
	word-break: break-word;
}

.c-article-satellite-subtitle {
	font-size: map-get($article--font-sizes, 'font-size-5');
	font-family: $article--font-family;
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-table__inline-caption {
	font-weight: normal;
	font-size: map-get($article--font-sizes, 'font-size-4');
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-table-container {
	font-family: $article--font-family;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-table-border {
	border: 1px solid map-get($article--grey-palette, 'grey-6');
}

.c-article-table-container table {
	border: 2px solid map-get($article--grey-palette, 'grey-6');
	border-collapse: collapse;
	border-spacing: 0;
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-family: $context--font-family-sans;
	margin-bottom: 0;
	width: 100%;
}

.c-article-table-container th,
.c-article-table-container td {
	padding: 6px;
	border-right: 1px solid map-get($article--grey-palette, 'grey-6');
	line-height: 1.15;
}

.c-article-table-container th p,
.c-article-table-container td p {
	word-break: normal;
}
.c-article-table-head {
	border-bottom: 5px solid map-get($article--grey-palette, 'grey-5');
}

.c-article-table-footer {
	padding: 10px;
}

.c-article-table-footer ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

.c-table-scroll-wrapper {
	position: relative;
}

.c-table-scroll-wrapper__content {
	overflow-x: auto;
	min-width: 100%;
}

.c-table-scroll-wrapper__fade::after {
	content: '';
	width: 30px;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), #fff);
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 1;
	transition: opacity 0.25s ease-in-out;
	will-change: opacity;
}

.c-table-scroll-wrapper__fade--transparent::after {
	opacity: 0;
}
