/**
 * Links
 * New underline styles to apply at the shared article level
 */

// mixins
@mixin _base-link {
	@include u-word-wrap;
	text-decoration: underline;
	text-decoration-thickness: .0625rem;
	text-underline-offset: .08em;

	&:hover {
		text-decoration: underline;
		text-decoration-thickness: .25rem;
		text-underline-offset: .08em;
	}
}

a {
	@include _base-link();
}
