
/* Inline variant styles */
.c-article-recommendations {
	padding: spacing(24);
	background-color: $article--recommendations-background-color;
	margin: 0 0 spacing(48);

	&-title {
		font-family: $context--font-family-sans;
		font-size: 1.25rem;
		font-weight: 700;
		margin: 0;
	}
}

.c-book-body .c-article-recommendations {
	margin: 0 0 spacing(64);
}

.c-book-body .c-article-recommendations-list,
.c-article-body .c-article-recommendations-list {
	display: flex;
	flex-direction: row;
	gap: 16px;
	max-width: 100%;
	margin: 0;
	padding: $article--vertical-spacing-medium 0 0;

	&__item {
		flex: 1;
	}

	@include media-query('sm', 'max') {
		flex-direction: column;
	}
}

.c-article-recommendations-list:before,
.c-article-recommendations-list:after {
	align-self: stretch;
	content: '';
	border-left: 1px solid $article--recommendations-border-color;
}

.c-article-recommendations-list:before,
.c-article-recommendations-list:after {
	@include media-query('sm', 'max') {
		border-bottom: 1px solid $article--recommendations-border-color;
		border-left: none;
	}
}

.c-article-recommendations-list__item {
	display: flex;
}

.c-article-recommendations-list .c-article-recommendations-list__item:first-child {
	order: -1;
}

.c-article-recommendations-list .c-article-recommendations-list__item:last-child {
	order: 1;
}

.c-article-recommendations-card {
	display: flex;
	flex-direction: column;
	font-family: $context--font-family-sans;
    overflow: hidden;
	position: relative;

	&__img {
		margin-bottom: spacing(8);
	}
}

.c-article-recommendations-card__img {
	@include media-query('sm', 'max') {
		display: none;
	}
}

.c-article-recommendations-card__heading {
	max-height: 5.6em;
	-webkit-box-orient: vertical;
	display: block;
	display: -webkit-box;
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-weight: 700;
	margin: 0 0 spacing(16);
	overflow: hidden !important;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	line-height: 1.4;

	@include media-query('sm', 'max') {
		margin: 0 0 spacing(8);
	}
}

.c-article-recommendations-card__link {
	color: $article--recommendation-card-link-color;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	&:hover,
	&:visited {
		color: $article--recommendation-card-link-color;
		text-decoration-thickness: 0.25rem;
		text-underline-offset: 0.08em;
	}

	.MathJax_Display {
		display: inline !important;
	}
}

.c-article-recommendations-card__main {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;

	@include media-query('sm', 'max') {
		flex-direction: column-reverse;
	}
}

.c-recommendations-column-switch .c-meta {
	margin-top: auto;
}

.c-meta .c-meta__item:first-child,
.c-article-recommendations-card__meta-type {
	font-weight: 700;
}

.c-article-meta-recommendations {
    margin-top: auto;
    padding: 0;
    font-family: $context--font-family-sans;
    font-size: .875rem;
    line-height: inherit;

    &__item-type {
    	font-weight: 700;
        border-right: 1px solid #d9d9d9;
        padding-right: spacing(8);
        margin-right: spacing(8);
    }
}

.c-article-meta-recommendations__access-type {
	@include media-query('sm', 'max') {
        border-right: 1px solid #d9d9d9;
        padding-right: spacing(8);
        margin-right: spacing(8);
	}
}

.c-article-recommendations-list:has(.c-article-meta-recommendations__access-type) .c-article-meta-recommendations:not(:has(> .c-article-meta-recommendations__access-type)) {
    padding: 0 0 1.5rem;

	@include media-query('sm', 'max') {
		padding: 0;
	}
}

.c-article-meta-recommendations__access-type + .c-article-meta-recommendations__date {
	display: block;

	@include media-query('sm', 'max') {
		display: inline;
	}
}

.c-article-body .c-article-recommendations-card__authors {
	display: none;
	font-family: $context--font-family-sans;
    font-size: .875rem;
    line-height: 1.5;
	margin: 0 0 spacing(8);

	@include media-query('sm', 'max') {
		display: block;
		margin: 0;
	}
}
