/**
 * Settings
 * Article component configuration
 */

/* Spacing defined by the 8-Point grid - https://spec.fm/specifics/8-pt-grid */
$article--vertical-spacing-large: 24px;
$article--vertical-spacing-medium: 16px;
$article--vertical-spacing-small: 8px;

$article--link-color: blue;
$article--link-icon-color: blue;
$article--link-border-color: green;
$article--link-focus-color: yellow;
$article--section-title-background-color: red;
$article--box-color: green;
$article--important-color: orange;
$article--key-step-color: blue;
$article--citations-count-color-primary: blue;
$article--citations-count-color-secondary: white;
$article--subject-background-color: white;
$article--bibliographic-information-list-layout: ('sm', 'lg');
$article--page-background-color: white;

$article--recommendation-card-link-color: blue;
$article--recommendations-border-color: blue;
$article--recommendations-background-color: white;

$article--blockquote-motto-color: blue;

$article--cta-button-background-color: blue;
$article--cta-button-background-hover-color: blue;
$article--cta-button-color: white;
$article--cta-button-hover-color: white;
$article--cta-button-icon-color: white;
$article--cta-button-icon-hover-color: white;
$article--cta-button-border-color: blue;
$article--cta-button-border-radius: 0;

$author-list--button-background-color: lightgray;
$author-list--button-text-color: black;
$author-list--button-hover-background-color: blue;
$author-list--button-hover-border-color: blue;
$author-list--button-border-radius: 0;

$article--font-family: $context--font-family-serif;

$article--font-sizes: (
	'font-size-1': 13px,
	'font-size-2': 14px,
	'font-size-3': 16px,
	'font-size-4': 17px,
	'font-size-5': 18px,
	'font-size-6': 24px,
	'font-size-7': 25px,
	'font-size-8': 28px,
	'font-size-9': 30px,
	'font-size-10': 40px,
	'font-size-11': 50px,
	'font-size-12': 60px
);

$article--grey-palette: (
	'grey-1': #000,
	'grey-2': #333,
	'grey-3': #666,
	'grey-4': #777,
	'grey-5': #888,
	'grey-6': #999,
	'grey-7': #aaa
);

$article-metrics-section-heading-font-weight: bold;
