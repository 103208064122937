/* NEW STYLING FOR PDF LINKS
Remove parent selector when all pdf links are adapted consistently across SN, Magazine, BMC and SL
*/
.c-pdf-download {
	display: flex;
	margin-bottom: $article--vertical-spacing-medium;
	max-height: 48px;

	@include media-query('sm', 'min') {
		max-height: none;
	}

	@include media-query('lg', 'min') {
		max-height: 48px;
	}
}

.c-pdf-download__link {
	display: flex;
	flex: 1;
	padding: 13px 24px;

	&:hover {
		text-decoration: none;
	}
}

// new
.c-pdf-download__text {
	padding-right: spacing(4);
	@include media-query('sm', 'max') {
		text-transform: capitalize;
	}
	@include media-query('sm', 'min') {
		padding-right: $article--vertical-spacing-small;
	}
}

.c-context-bar--sticky .c-pdf-download {
	margin-bottom: 0;
	display: block;
	white-space: nowrap;
}

.c-pdf-download .u-sticky-visually-hidden {
	@include media-query('sm', 'max') {
		@include u-visually-hidden;
	}
}

.c-pdf-container {
	display: flex;
	justify-content: flex-end;

	@include media-query('sm', 'max') {
		& .c-pdf-download {
			display: flex;
			flex-basis: 100%;
		}
	}
}

.c-pdf-container {
	& .c-pdf-download + .c-pdf-download {
		margin-left: $article--vertical-spacing-medium;
	}
}

.c-article-extras .c-pdf-container {
	width: 100%;
	flex-wrap: wrap;
	& .c-pdf-download {
		width: 100%;
	}

	& .c-pdf-download + .c-pdf-download {
		margin-left: 0;
	}
}

.c-context-bar--sticky .c-pdf-download__link {
	@include media-query('sm', 'min') {
		flex: 1 1 183px;
		align-items: center;
	}
	// solve problems with high level of zoom
	@media only screen and (max-width: 320px) {
		padding: $article--vertical-spacing-medium;
	}
}

.article-page--commercial {
	.c-pdf-container {
		display: block;
	}
	/* PDF link show/hide in main body within c-pdf-button__container depending on viewport */
	.c-article-main-column .c-pdf-button__container .c-pdf-download {
		display: none;

		@include media-query('lg', 'max') {
			display: block;
		}
	}
}

