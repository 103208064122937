/**
 * Article Metrics
 * Article metrics styles
 */

.c-article-metrics__heading {
	line-height: 1.4;
	margin-bottom: $article--vertical-spacing-medium;

	a {
		color: inherit;
	}
}

.c-article-metrics__body {
	display: flex;
	flex-wrap: wrap;
	font-family: $context--font-family-sans;
}

.c-article-metrics__section--left {
	font-size: map-get($article--font-sizes, 'font-size-3');
	flex-shrink: 1;
	flex-basis: 100%;
	list-style: none;
	margin: 0;
	padding: 0;

	@include media-query('md', 'min') {
		flex-basis: 60%;
	}

	> li:last-child {
		margin-right: 0;
	}

	p {
		line-height: $article--vertical-spacing-large;
	}
}

.c-article-metrics__explanation {
	color: map-get($article--grey-palette, 'grey-1');
	font-size: map-get($article--font-sizes, 'font-size-2');
	line-height: $article--vertical-spacing-large;

	@include media-query('md', 'min') {
		padding-left: 60px;
	}
	@include media-query('lg', 'min') {
		padding-left: 80px;
	}
}

.c-article-metrics__section--right {
	flex-grow: 0;
	flex-shrink: 1;

	@include media-query('md', 'min') {
		flex-basis: 40%;
	}
}

.c-article-metrics__section-heading {
	line-height: 1.4;
	margin-bottom: $article--vertical-spacing-medium;
	font-weight: $article-metrics-section-heading-font-weight;
	border-bottom: 2px solid map-get($article--grey-palette, 'grey-1');
}

.c-article-metrics__numbers {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: $article--vertical-spacing-medium;

	li {
		padding-top: $article--vertical-spacing-small;
		padding-right: $article--vertical-spacing-medium;
		flex-grow: 1;

		@include media-query('lg', 'min') {
			padding-top: 0;
		}
	}
}

.c-article-metrics__access-citation {
	display: flex;
	flex-flow: column;
	color: map-get($article--grey-palette, 'grey-1');

	&:nth-child(1) {
		order: 2;
	}

	&:nth-child(2) {
		order: 1;
	}

	dt {
		font-size: map-get($article--font-sizes, 'font-size-6');
		font-weight: 500;
	}

	dd {
		font-size: map-get($article--font-sizes, 'font-size-5');
		margin: 0;
	}
}

.c-article-metrics__image img {
	max-height: 128px;
	max-width: 100%;
	display: block;
	margin: 0 auto;
	min-width: 80px;

	@include media-query('md', 'min') {
		margin: 0;
	}
}

.c-article-metrics__altmetric-donut {
	padding-bottom: $article--vertical-spacing-large;

	@include media-query('sm', 'min') {
		display: flex;
		align-items: center;
	}
}

.c-article-metrics__legend {
	margin-top: $article--vertical-spacing-large;

	@include media-query('sm', 'min') {
		margin-top: 0;
	}

	@include media-query('md', 'min') {
		flex-basis: 75%;
		justify-content: right;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
	}

	li {
		flex-basis: 100%;
		padding: $article--vertical-spacing-small 0 0 $article--vertical-spacing-medium;

		@include media-query('md', 'min') {
			flex: 0 1 130px;
		}
	}
	@supports (display: grid) and (grid-auto-rows: auto) {
		ul {
			display: grid;
			grid-column-gap: 8px;
			grid-auto-rows: auto;
			grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

			@include media-query('sm', 'min') {
				padding-left: $article--vertical-spacing-medium;
				grid-template-columns: repeat(2, 50%);
			}

			@include media-query('lg', 'min') {
				grid-template-columns: repeat(3, 33%);
			}

			li {
				font-size: map-get($article--font-sizes, 'font-size-2');
			}
		}
	}
}

.c-article-metrics__posts .c-card__title {
	font-size: map-get($article--font-sizes, 'font-size-4');

	& + span {
		color: map-get($article--grey-palette, 'grey-4');
		font-size: map-get($article--font-sizes, 'font-size-3');
	}

	a {
		color: inherit;
	}
}

.c-article-metrics__posts {
	li {
		border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
		margin-bottom: $article--vertical-spacing-medium;
		padding-bottom: $article--vertical-spacing-medium;

		&:first-child {
			padding-top: 0;
		}
	}
}

.c-article-metrics__altmetric-key {
	border-radius: 50%;
	display: block;
	float: left;
	height: 16px;
	margin-right: $article--vertical-spacing-small;
	width: 16px;

	> span {
		font-weight: bold;
	}

	$altmetric-colors: (
		twitter: #2f90b9,
		blogs: #ffc200,
		facebook: #071d70,
		googleplus: #912470,
		news: #b60000,
		linkedin: #00bfff,
		reddit: #b9ddeb,
		f1000: #cb2d2d,
		pinterest: #c30,
		qna: #efefef,
		video: #98c973,
		wikipedia: #c7c7c7,
		reviews: #d45bb0,
		citeulike: #bcd2ef,
		mendeley: #a60000,
		connotea: #ff4040
	);

	@each $source, $color in $altmetric-colors {
		&--#{$source} {
			background-color: $color;
		}
	}

	& + span {
		display: block;
		margin-left: calc(16px + 8px);
	}
}
