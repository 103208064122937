.c-aod {
	background: #dae5ea;
	padding: 20px;
	margin-bottom: $article--vertical-spacing-large;
	font-size: map-get($article--font-sizes, 'font-size-3');
}

.c-aod__prompt {
	display: block;
	margin-bottom: $article--vertical-spacing-small;
}
.c-aod__invalid {
	display: block;
	margin-top: $article--vertical-spacing-small;
}

.c-aod input {
	padding: 5px;
	border: 1px solid $article--cta-button-background-color;
}

.c-aod input[type="submit"] {
	background: $article--cta-button-background-color;
	color: $article--cta-button-color;
	padding: 5px 10px;
}

.c-access-options {
	margin: $article--vertical-spacing-medium 0 40px;

	&__heading {
		font-weight: bold;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: disc inside;
	}

	&__list > li {
		font-size: map-get($article--font-sizes, 'font-size-3');
		line-height: 1.4;
	}
}

.c-cod {
	font-size: map-get($article--font-sizes, 'font-size-3');
	display: block;
	width: 100%;
}

.c-cod__form {
	background: #ebf0f3;
}

.c-cod__prompt {
	margin: 0 0 $article--vertical-spacing-large;
	font-size: map-get($article--font-sizes, 'font-size-5');
	line-height: 1.3;
}

.c-cod__label {
	display: block;
	margin: 0 0 4px;
}

.c-cod__row {
	display: flex;
	margin: 0 0 $article--vertical-spacing-medium;

	&:last-child {
		margin: 0;
	}
}

.c-cod__input {
	padding: 13px;
	margin: 0;
	border: 1px solid map-get($article--grey-palette, 'grey-6');
	border-radius: 2px;
	flex-basis: 75%;
	flex-shrink: 0;
}

.c-cod__input--submit {
	margin-left: $article--vertical-spacing-small;
	flex-shrink: 1;
	background-color: $article--cta-button-background-color;
	color: $article--cta-button-color;
	border: 1px solid $article--cta-button-background-color;
	transition: background-color .2s ease-out,color .2s ease-out;
}

.c-cod__input--submit-single {
	margin: 0;
	flex-shrink: 0;
	flex-basis: 100%;
}

.c-cod__input--submit:hover,
.c-cod__input--submit:focus {
	color: $article--cta-button-background-color;
	background-color: $article--cta-button-color;
}

.c-pdf-download__link {
	.u-icon {
		padding-top: 2px;
	}
}
