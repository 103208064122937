/**
 * Article Enhanced
 * Article styles for browsers that pass CTM
 */

/* reset list styles */
.c-article-author-institutional-author,
.c-article-author-information__list,
.c-article-references,
.c-article-author-affiliation__list,
.c-article-author-authors-search,
.c-article-supplementary__list,
.c-article-further-reading__list,
.c-article-footnote--listed,
.c-article-references__nav,
.c-article-change-list,
.c-section-author-list  {
	list-style: none;
	padding: 0;
}

.c-section-author-list {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-section-author-list > li {
	display: inline;
	font-weight: bold;
}

.c-article-associated-content__container .c-article-associated-content__title,
.c-article-section__title {
	border-bottom: 2px solid map-get($article--grey-palette, 'grey-6');
	font-size: 1.25rem;
	padding-bottom: $article--vertical-spacing-small;
	margin: 0;

	@include media-query('md', 'min') {
		font-size: map-get($article--font-sizes, 'font-size-6');
		line-height: 1.24;
	}
}

.c-article-associated-content__container .c-article-associated-content__title {
	margin-bottom: $article--vertical-spacing-small;
}

.c-article-body p,
.c-article-body ul,
.c-article-body ol,
.c-article-body dl {
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-references p,
.c-bibliographic-information__column p,
.c-bibliographic-information__column button {
	margin-bottom: 16px;
}


.c-article-section {
	clear: both;
}

.c-article-section__content {
	padding-top: $article--vertical-spacing-small;
	margin-bottom: 40px;
	@include media-query('lg', 'max') {
			padding-left: 0;
	}
}

.c-article-section__content--separator {
	border-top: 2px solid map-get($article--grey-palette, 'grey-6');
	padding-top: 40px;
}

.c-article__pill-button {
	padding: $article--vertical-spacing-small $article--vertical-spacing-medium $article--vertical-spacing-small 20px;
	background-color: white;
	border: 4px solid $article--link-border-color;
	border-radius: 20px;
	font-size: map-get($article--font-sizes, 'font-size-2');
	line-height: 1.4;
	font-weight: 700;
	margin-bottom: 10px;
	font-family: $context--font-family-sans;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
}

.c-article__pill-button:hover {
	background-color: $article--link-color;
	color: white;
	text-decoration: none;
}

.c-article__pill-button:focus {
	outline: 0;
	box-shadow: 0 0 0 3px $article--link-focus-color;
	text-decoration: none;
}

.c-article__pill-button:active,
.c-article__pill-button:hover {
	box-shadow: 0 0 0 0;
}

.c-article__pill-button svg {
	height: 0.8em;
	margin-left: spacing(2);
}

.c-article-section__illustration {
	font-size: map-get($article--font-sizes, 'font-size-2');
	float: none;
	margin: 0 auto $article--vertical-spacing-large;
	padding: 0;
}

.c-article-section__illustration-right {
	@include media-query('sm', 'min') {
		margin: $article--vertical-spacing-small 0 $article--vertical-spacing-medium $article--vertical-spacing-large;
		float: right;
	}
}

.c-article__sub-heading {
	font-size: map-get($article--font-sizes, 'font-size-5');
	font-weight: normal;
	font-family: $context--font-family-sans;
	font-style: normal;
	line-height: 1.3;
	color: map-get($article--grey-palette, 'grey-1');
	margin: 24px 0 $article--vertical-spacing-small;

	@include media-query('md', 'min') {
		font-size: map-get($article--font-sizes, 'font-size-6');
		line-height: 1.24;
	}

	&--small {
		font-size: map-get($article--font-sizes, 'font-size-5');
		font-weight: bold;
	}
}

.c-article__sub-heading:first-child {
	margin-top: 0;
}

.c-article__sub-heading--divider::before {
	content: '';
	display: block;
	width: 30px;
	height: 4px;
	margin-top: 40px;
	margin-bottom: $article--vertical-spacing-medium;
	background: map-get($article--grey-palette, 'grey-1');
}

.c-article-body .c-article-access-provider,
.c-notes {
	padding: $article--vertical-spacing-small $article--vertical-spacing-medium;
	border: 1px solid map-get($article--grey-palette, 'grey-6');
	border-left: none;
	border-right: none;
	margin: $article--vertical-spacing-large 0;

	&__text {
		padding-top: 2px;
		padding-bottom: 2px;
		margin-bottom: 0;
		font-size: map-get($article--font-sizes, 'font-size-3');
		color: map-get($article--grey-palette, 'grey-2');
		text-align: center;
	}
}

.c-article-change-list__item > ins {
	text-decoration: none;
}

.c-article-change-list__details,
.c-article-change-list__details > p:first-child {
	display: inline;
}

.c-article-references__heading {
	font-weight: bold;
	font-size: map-get($article--font-sizes, 'font-size-4');
	margin: $article--vertical-spacing-large 0 4px;
}

.c-article-references__item {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
	padding-bottom: $article--vertical-spacing-medium;
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-references__item::before {
	content: attr(data-counter);
	font-size: map-get($article--font-sizes, 'font-size-6');
	line-height: 1.4;
	text-align: right;

	@include media-query('lg', 'max') {
		font-size: map-get($article--font-sizes, 'font-size-5');
		line-height: inherit;
	}
}

.c-article-references__item > p.c-article-references__text,
.c-article-references__item > h3 {
	flex: 1;
	padding-left: $article--vertical-spacing-small;
}

.c-article-references__text {
	margin-bottom: $article--vertical-spacing-small;
}

.c-article-references__links {
	display: flex;
	flex-basis: 100%;
	justify-content: flex-end;
	flex-wrap: wrap;
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-weight: bold;
	margin: 0;
	padding: 0;
	list-style: none;
}

.c-article-references__links a {
	padding-left: $article--vertical-spacing-small;
}

.c-article-references__links a:first-child {
	padding-left: 0;
}

.c-article-references__download {
	text-align: right;
}

.c-article-references__download > a {
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-weight: bold;
	font-family: $context--font-family-sans;
}

.c-article-references__download svg {
	margin-left: 4px;
	margin-top: 2px;
}

.c-article-author-information__list {
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-author-information__authors-list {
	padding: 0;
	margin: 0;
	list-style: none;
	font-weight: bold;
}

.c-article-author-information__item {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-author-information__subtitle {
	font-weight: 700;
	margin: 0 0 $article--vertical-spacing-small 0;
}

.c-article-author-affiliation__list > li {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-body .c-article-author-affiliation__address {
	color: inherit;
	font-weight: 700;
	margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-item {
	display: inline;
	margin-left: 0;
}

.c-article-authors__list {
	padding: 0;
	list-style: none;
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-authors-search {
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-authors-search__title {
	color: map-get($article--grey-palette, 'grey-3');
	font-weight: 700;
	font-family: $context--font-family-sans;
	margin: 0;
	padding: 0;
	font-size: map-get($article--font-sizes, 'font-size-4');
}

.c-article-authors-search__item {
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-family: $context--font-family-sans;
}

.c-article-authors-search__text {
	margin: 0;
}

.c-article-author-institutional-author {
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-author-institutional-author__name {
	font-size: map-get($article--font-sizes, 'font-size-4');
	font-weight: 700;
	margin: 0;
	padding: 0;
}

.c-article-author-institutional-author__author-list {
	list-style: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: $article--vertical-spacing-large;

	&--popup {
		clear: both;
		margin-bottom: $article--vertical-spacing-medium;
	}
}

.c-article-author-institutional-author__author-name {
	display: inline;
	list-style: none;
}

.c-article-author-institutional-author__sub-division {
	line-height: 1.76;
	margin: 0;
}

.c-article-author-institutional-author__author-list .c-article-author-institutional-author__author-list .c-article-author-institutional-author__sub-division {
	color: map-get($article--grey-palette, 'grey-3');
}

.c-article-supplementary {
	margin-bottom: $article--vertical-spacing-large;

	&__item {
		margin-bottom: $article--vertical-spacing-large;
		position: relative;

		> span {
			width: 40px;
			line-height: 1.4;
			font-weight: 400;
			text-align: right;
			position: absolute;
			font-size: map-get($article--font-sizes, 'font-size-6');
		}
	}

	&__external {
		margin-bottom: $article--vertical-spacing-medium;
	}

	&__description {
		> * {
			margin-bottom: 0;
		}
	}
}

.c-article-fullwidth-content {

	&__infographic {
		position: relative;
		margin-bottom: $article--vertical-spacing-large;
		padding: 0;
	}

	&__image {
		border: 4px solid $article--box-color;
		position: relative;

		img {
			max-width: 100%;
		}
	}

	&__caption {
		margin: 1px 0 20px 0;
	}
}


.c-article-supplementary__title {
	margin: 0 0 $article--vertical-spacing-small;
	line-height: 1.5;
}

.c-article-supplementary--enumerated .c-article-supplementary__title,
.c-article-supplementary--enumerated .c-article-supplementary__description {
	margin-left: 50px;
}

.c-article-further-reading__list {
	margin: 0;
}

.c-article-further-reading__item {
	border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
	margin-bottom: $article--vertical-spacing-medium;
	position: relative;

	&:last-child {
		border: none;
	}
}

.c-article-further-reading__title {
	margin: 0;
	padding: 0;
	line-height: 1.5; // temporary solution until standarising the font-sizes in headings
}

.c-article-further-reading__authors-list {
	padding: 0;
	list-style: none;
	margin: $article--vertical-spacing-small 0 0 0;
	font-size: map-get($article--font-sizes, 'font-size-1');
	line-height: 1.4;
	color: map-get($article--grey-palette, 'grey-3');
	font-family: $context--font-family-sans;
}

.c-article-further-reading__authors-list li {
	display: inline;
}

.c-article-further-reading__journal-title {
	color: map-get($article--grey-palette, 'grey-3');
	font-size: map-get($article--font-sizes, 'font-size-1');
	margin-bottom: $article--vertical-spacing-large;
	padding: 0;
	font-family: $context--font-family-sans;
}

.c-article-license {
	font-size: map-get($article--font-sizes, 'font-size-3');
	margin-bottom: $article--vertical-spacing-large;
}

.c-article-license__link {
	float: left;
	margin-right: 10px;
}

.c-article-license__badge {
	margin-top: $article--vertical-spacing-small;
}

.c-article-subject-list {
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-family: $context--font-family-sans;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0 0 $article--vertical-spacing-large;
}

.c-article-subject-list__subject {
	background-color: $article--subject-background-color;
	border-radius: 20px;
	padding: 4px 10px;
	font-weight: 700;
	margin-right: 15px;
	margin-bottom: $article--vertical-spacing-medium;
	flex: 0 1 auto;
}

.c-article-satellite-article-link {
	font-family: $context--font-family-serif;
}

.c-article-compound-container {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-compound-details {
	display: inline-block;
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-compound-details p {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-compound-details__image {
	margin-bottom: $article--vertical-spacing-medium;
	max-width: 100%;
}

.c-article-compound-details__links {
	color: map-get($article--grey-palette, 'grey-3');
	font-size: map-get($article--font-sizes, 'font-size-2');
}

.c-article-compound-details__synonym {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-article-compound-details__synonym-list {
	padding: 0;
	list-style: none;
}

.c-article-compound-details__synonym-list > li {
	display: inline;
}

.c-bibliographic-information {
	display: flex;
	padding-top: $article--vertical-spacing-small;

	@include media-query('lg', 'max') {
		padding-top: 0;
		display: block;
	}

	&__value {
		font-size: map-get($article--font-sizes, 'font-size-3');
		display: block;
	}
}

.c-bibliographic-information__column:first-child {
	width: 81px;
	flex: 0 0 81px;

	@include media-query('lg', 'max') {
		width: 100%;
	}
}

.c-bibliographic-information__column:last-child {
	flex: 1;
}

.c-bibliographic-information__column--border {
	border-right: 1px solid map-get($article--grey-palette, 'grey-6');
	margin-right: $article--vertical-spacing-large;

	@include media-query('lg', 'max') {
		border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
		padding-bottom: $article--vertical-spacing-small;
		margin-bottom: $article--vertical-spacing-small;
		border-right: 0;
		margin-right: 0;
	}
}

.c-bibliographic-information__list {
	@include u-list-reset;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 32px;

	&-item {
		flex: 0 0 calc((100% - 64px) / 3);
		box-sizing: border-box;
		font-size: map-get($article--font-sizes, 'font-size-3');
		font-family: $context--font-family-sans;

		@include media-query('lg', 'max') {
			flex: 0 0 100%;
		}

		h4 {
			font-style: normal;
			font-size: map-get($article--font-sizes, 'font-size-3');
		}

		p {
			margin-bottom: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		&--full-width {
			flex: 0 0 100%;
		}
	}
}

.c-bibliographic-information__citation,
.c-bibliographic-information__download-citation {
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-3');
	margin-bottom: $article--vertical-spacing-small;
}

.c-bibliographic-information__download-citation {
	margin-bottom: $article--vertical-spacing-large;

	svg {
		margin-left: 4px;
		margin-top: 2px;
	}
}

.c-code-block {
	padding: 20px;
	margin: 0 0 $article--vertical-spacing-large 0;
	border: 1px solid $context--foreground-light;
	font-family: monospace;
}

.c-code-block__heading {
	margin-bottom: $article--vertical-spacing-medium;
	font-weight: normal;
}

.c-code-block__line {
	word-wrap: break-word;
	white-space: pre-wrap;
	display: block;
}

.c-stack {
	display: inline-table;
	vertical-align: top;
}

.c-stack sup,
.c-stack sub {
	display: table-row;
	line-height: 1;
}

.c-literal {
	font-family: monospace;
}

.c-blockquote {
	@include u-clearfix;
	border-left: 4px solid map-get($article--grey-palette, 'grey-6');
	padding-left: $article--vertical-spacing-medium;
	margin: $article--vertical-spacing-large 0;
	position: relative;

	&:before, &:after {
		height: 7px;
		border-left: 4px solid white;
		position: absolute;
		margin-left: -4px;
		left: 0;
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}

	&:first-child {
		margin-top: 0;
	}

	&__body > * {
		margin-bottom: 0;
	}

	&--motto {
		border-color: $article--blockquote-motto-color;
	}
}

.c-book-review {
	margin-top: 40px;
	margin-bottom: $article--vertical-spacing-large;
	padding: 30px 0 20px 20px;
	border-bottom: 2px solid;
	border-top: 2px solid;
	border-image: linear-gradient(to right, white, white 23%, $context--foreground-light 0, $context--foreground-light 74%, white 23%, white) 10;
	font-size: map-get($article--font-sizes, 'font-size-1');

	&__title {
		font-weight: 700;
		font-size: map-get($article--font-sizes, 'font-size-4');
		line-height: 1.3;
	}

	&__image {
		margin: 10px 30px 10px 0;
		float: left;
	}

	img {
		box-shadow: 2px 2px 10px rgba(0,0,0,0.25);
	}

	&__list {
		padding: 0;
		list-style: none;
		display: block;
		visibility: visible;
		margin-top: 0;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: $article--vertical-spacing-large;
		}
	}

	&__item {
		display: inline;
	}

	&__additional-info {
		margin-bottom: 10px;
	}
}

.c-key-step {
	border: 1px solid $article--key-step-color;
	margin-bottom: $article--vertical-spacing-large;
	padding: 15px 15px 6px 15px;
}

.c-key-step--important {
	border-color: $article--important-color;
}

.c-key-step__heading {
	background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
	color: $article--key-step-color;
	font-family: $context--font-family-sans;
	font-weight: bold;
	padding-left: 20px;
	text-transform: uppercase;
}

.c-key-step--important > .c-key-step__heading {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'109.508%2034.751%2016%2016'%20enable-background%3D'new%20109.508%2034.751%2016%2016'%3E%3Cpath%20fill%3D'%23AB5306'%20d%3D'M125.382%2048.933l-7.35-12.914c-.314-.526-.734-.526-1.05%200l-7.35%2012.913c-.314.525%200%20.945.526.945h14.7c.524%200%20.84-.42.524-.945zm-8.61-9.555h1.47c.21%200%20.316.105.316.315l-.21%205.774c0%20.21-.105.315-.315.315h-1.05c-.21%200-.315-.105-.315-.315l-.21-5.774c0-.21.104-.315.315-.315zm.736%209.45c-1.05%200-1.155-.525-1.155-1.05%200-.63.21-1.05%201.155-1.05.945%200%201.155.42%201.155%201.05%200%20.525-.21%201.05-1.155%201.05z'%2F%3E%3C%2Fsvg%3E");
	color: $article--important-color;
}

.c-key-step__body {
	color: map-get($article--grey-palette, 'grey-3');
	font-size: map-get($article--font-sizes, 'font-size-3');
	margin: 0;
}

.c-key-step__body > p {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-troubleshooting-step {
	background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
	font-family: $context--font-family-sans;
	font-weight: bold;
	font-size: map-get($article--font-sizes, 'font-size-3');
	padding-left: 20px;
	text-transform: uppercase;
}

.c-timing-step {
	color: map-get($article--grey-palette, 'grey-3');
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-3');
	margin: 0 0 $article--vertical-spacing-small 0;
}

.c-article-box {
	border: 2px solid $article--box-color;
	margin: 0 0 $article--vertical-spacing-large 0;
	padding: 0;
}

.c-article-box__container {
	margin: 0;
	overflow: hidden;
	padding: 20px 20px 30px 20px;
}

.c-article-box__container-title {
	margin-bottom: $article--vertical-spacing-small;
	font-weight: bold;
}

.c-article-box__content > * {
	margin-bottom: 0;
}

.c-article-box__content > * + * {
	margin-top: $article--vertical-spacing-large;
}

.c-article-box__controls {
	position: relative;
	margin-top: -56px;
}

.c-article-box__controls button {
	display: block;
	font-weight: bold;
	font-size: map-get($article--font-sizes, 'font-size-2');
	background-color: $article--box-color;
	color: $article--link-color;
	border: none;
	padding: 10px 20px 6px 20px;
	width: 100%;
	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-article-box__button-text:after {
	content: '';
	width: 20px;
	height: 10px;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'12'%20height%3D'7'%20viewBox%3D'0%200%2012%207'%3E%3Cpath%20fill%3D'%23#{$article--link-icon-color}'%20d%3D'M9.6%200l-3.6%204.3-3.6-4.3h-2.4l6%207%206-7h-2.4z'%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
}

.c-article-box--expanded .c-article-box__button-text:after {
	transform: rotate(180deg);
}

.c-article-box__fade {
	height: 56px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .38) 25%, rgba(255, 255, 255, .75) 50%, rgba(255, 255, 255, .85) 75%, #fff 100%);
}

.c-article-box--expanded .c-article-box__controls {
	margin-top: 0;
}

.c-article-box--expanded .c-article-box__fade {
	display: none;
}

.c-article-footnote {
	border-bottom: 1px solid map-get($article--grey-palette, 'grey-6');
	margin-bottom: $article--vertical-spacing-large;
	padding: 20px 30px;

	p {
		margin-bottom: 0;
	}

	p + p {
		margin-top: $article--vertical-spacing-medium;
	}
}

.c-article-footnote--listed {
	border: none;
	padding: 0;

	&__item {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: $article--vertical-spacing-medium;
		margin-bottom: $article--vertical-spacing-medium;
	}

	&__content {
		flex: 1;
		padding-left: 12px;
		@include u-word-wrap;
	}
}

.c-article-footnote--listed__item::before {
	content: attr(data-counter);
	font-size: map-get($article--font-sizes, 'font-size-6');
	line-height: 1.4;
	text-align: right;
}

.c-article-share-box {
	font-family: $context--font-family-sans;
	margin-bottom: $article--vertical-spacing-large;

	&__title {
		font-weight: 700;
		margin-bottom: $article--vertical-spacing-small;
	}

	&__description {
		font-size: map-get($article--font-sizes, 'font-size-3');
		margin-bottom: $article--vertical-spacing-small;
	}

	&__no-sharelink-info {
		font-size: map-get($article--font-sizes, 'font-size-1');
		font-weight: 700;
		padding-top: 4px; /* make equal height with share button to prevent redraw */
		margin-bottom: $article--vertical-spacing-large;
	}

	&__only-read-input {
		border: 1px solid map-get($article--grey-palette, 'grey-6');
		display: inline-block;
		box-sizing: content-box;
		height: 24px;
		margin-bottom: $article--vertical-spacing-small;
		font-size: map-get($article--font-sizes, 'font-size-2');
		font-weight: 700;
		padding: $article--vertical-spacing-small 10px;

		&--highlighted {
			@include keyframes-generator('transparent-to-yellow');
			animation: 'transparent-to-yellow' 1.5s ease;
		}
	}

	&__additional-info {
		color: map-get($article--grey-palette, 'grey-3');
		font-size: map-get($article--font-sizes, 'font-size-1');
	}

	&__button {
		background: white;
		border: 1px solid $article--link-color;
		box-sizing: content-box;
		color: $article--link-color;
		font-size: map-get($article--font-sizes, 'font-size-3');
		line-height: 1.5;
		margin-bottom: $article--vertical-spacing-small;
		padding: $article--vertical-spacing-small 20px;
		text-align: center;

		&--link-like {
			background-color: transparent;
			border: none;
			color: $article--link-color;
			cursor: pointer;
			font-size: map-get($article--font-sizes, 'font-size-2');
			margin-bottom: $article--vertical-spacing-small;
			margin-left: 10px;
		}
	}
}

.c-pan-zoom-controls {
	text-align: center;
	background: $article--box-color;
	border-bottom: 6px solid $article--box-color;
	padding: $article--vertical-spacing-small 10px;

	ul {
		list-style: none;
		display: inline-flex;
		margin: 0;
		padding: 0;
	}

	li {
		border-left: 1px solid white;
		padding: 4px;
	}

	li:first-child {
		border-left: none;
	}

	button {
		border: none;
		padding: 0 10px;
		background-color: transparent;
		color: $article--link-color;
	}

	button:hover,
	button:focus {
		text-decoration: underline;
	}
}

.c-pan-zoom-controls__zoom-in,
.c-pan-zoom-controls__zoom-out {
	button {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 16px auto;
		width: 16px;
		height: 16px;
		padding: 4px 20px;
	}
}

.c-pan-zoom-controls__zoom-in {
	button {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%23#{$article--link-icon-color}'%20d%3D'M6%200h4v16h-4v-16zm-6%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
	}
}

.c-pan-zoom-controls__zoom-out {
	button {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%23#{$article--link-icon-color}'%20d%3D'M0%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
	}
}

.c-article-equation {
	margin-bottom: $article--vertical-spacing-large;
	position: relative;
	width: 100%;
	display: table;
	table-layout: fixed;

	.MathJax_Display > .MathJax {
		display: block !important;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}

	#MathJax_Zoom {
		background-color: #f7fbfe; // tbd
		border: 1px solid #97bfd8; // tbd
		border-radius: 2px;
		box-shadow: 0 0 5px 2px rgba(51, 51, 51, .2);
	}

	&__content {
		display: block;
		text-align: left;
		vertical-align: middle;

		@include media-query('md') {
			display: table-cell;
			width: 90%;
		}

		> .MathJax {
			display: block !important;
			max-width: 100%;
			overflow-x: auto;
			overflow-y: hidden;
		}
	}

	&__number {
		display: block;
		font-family: $context--font-family-sans;
		text-align: left;
		vertical-align: middle;
		margin: 12px 0 16px;

		@include media-query('md') {
			display: table-cell;
			width: 10%;
			white-space: nowrap;
			text-align: right;
			padding-left: 16px;
		}
	}
}

.MathJax_SVG svg {
	max-width: 100%;
}

#MathJax_Zoom svg {
	max-width: none;
}


.c-article-equation__number {
	min-width: 35px;
}

.c-article-orcid {
	margin-block-start: 0;

	&__text {
		font-size: map-get($article--font-sizes, 'font-size-3');
	}

	&:before {
		display: inline-block;
		width: 24px;
		height: 24px;
		content: url("data:image/svg+xml;charset=utf-8,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v24h-24z' fill='%23fff' opacity='0'/%3E%3Cpath d='m12 20c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm-3.01799709-13.09169471c-.16628978-.16109322-.36635416-.24163862-.60019916-.24163862-.21825533 0-.410525.08444276-.57681478.25333081-.16628977.16888806-.24943341.36245684-.24943341.58071218 0 .22345188.08314364.41961891.24943341.58850696.16628978.16888806.35855945.25333082.57681478.25333082.233845 0 .43390938-.08314364.60019916-.24943342.16628978-.16628977.24943342-.36375592.24943342-.59240436 0-.233845-.08314364-.43131115-.24943342-.59240437zm-1.30173065 2.36492841v7.1712107h1.41865256v-7.1712107zm5.97732434.02508949h-2.9924703v7.14612121h2.9106249c.7673039 0 1.4476365-.1483432 2.0410182-.445034s1.0511995-.7152915 1.3734671-1.2558144c.3222677-.540523.4833991-1.1603247.4833991-1.8594238 0-.6854582-.1602789-1.3027023-.4808414-1.8517509-.3205625-.5490485-.7707074-.9753221-1.3504481-1.2788334-.5797408-.30351132-1.2413173-.45526471-1.9847495-.45526471zm-.1432294 6.06678581h-1.4681008v-4.9874504h1.4220628c.7877654 0 1.4143875.2233674 1.8798852.6701087.4654977.4467414.698243 1.05546.698243 1.8261742 0 .7434322-.2310402 1.3444779-.6931277 1.8031551-.4620874.4586773-1.0750688.6880124-1.8389625.6880124z' fill='%23999'/%3E%3C/g%3E%3C/svg%3E");
		margin-left: -3px;
		padding-right: 4px;
		vertical-align: middle;
	}
}

.c-article-editorial-summary__container {
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-3');
	.c-article-editorial-summary__article-title {
		@include u-heading(h3);
	}

	.c-article-editorial-summary__content p:last-child {
		margin-bottom: 0;
	}

	.c-article-editorial-summary__content--less {
		max-height: 9.5rem;
		overflow: hidden
	}

	.c-article-editorial-summary__button {
		@include u-link-interface;
		background-color: white;
		border: none;
		color: $article--link-color;
		font-size: map-get($article--font-sizes, 'font-size-2');
		margin-bottom: $article--vertical-spacing-medium;
	}
}

.c-article-associated-content__container {
	.c-article-associated-content__collection {
		&-label {
			font-size: map-get($article--font-sizes, 'font-size-2');
			line-height: 1.4;
		}

		&-title {
			line-height: 1.3;
		}
	}
}

#uptodate-client {
	display: none !important;
}

/**
 * Copied from https://github.com/springernature/bunsen/blob/master/webapp/src/webapp/assets/scss/partials/_sticky-banner.scss
 * Sticky Banner
 * Persistent (after scrolling past sticky point) horizontal bar
 * at top of page containing call-to-action elements
 *
 */

 .c-context-bar {
	position: relative;
	width: 100%;
	box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
}

.c-context-bar--sticky {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;

	// solve problems with high level of zoom
	@media only screen and (max-width: 320px) {
		left: auto;
		right: 0;
	}

	&:after {
		background: #fff;
		@supports (backdrop-filter: blur(10px)) {
			background: linear-gradient(to top, rgba(255, 255, 255, 0.75) 50%, #fff);
			backdrop-filter: blur(10px);
		}
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		content: '';
		z-index: -1;

		// solve problems with high level of zoom
		@media only screen and (max-width: 320px) {
			background-color: $article--page-background-color;
			background-image: none;
			box-shadow: none;
		}
	}
}

.c-context-bar--no-button {
	&.c-context-bar--sticky {
		display: none;
		@include media-query('sm', 'min') {
			display: block;
		}
	}
}

.c-context-bar--sticky .c-context-bar__container {
	padding: 16px;
	@include media-query('sm', 'min') {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		min-height: 40px;
	}
}

.c-context-bar__title {
	display: none;
}

.c-context-bar--sticky .c-context-bar__title,
.c-context-bar--sticky .c-context-bar__title p {
	display: none;
	@include media-query('sm', 'min') {
		display: block;
		flex: 1;
		padding-right: 16px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.c-context-bar--sticky .c-context-bar__title p {
	margin-bottom: 0;
}

.article__teaser {
	overflow: hidden;
	position: relative;
	font-family: $article--font-family;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		min-height: min(200px, 50%); // make the fade 200px or 50% if cannot be 200px
		background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
	}
}


.c-pdf-preview__embed {
	display: none;

	@include media-query('sm', 'min') {
		display: block;
		margin-bottom: $article--vertical-spacing-large;
	}
}

.c-pdf-preview__embed--height {
	height: 1000px;
}

.c-pdf-preview__info {
	display: block;

	@include media-query('sm', 'min') {
		display: none;
	}
}

/* Add to remove white space created by img src */
img[src*="delivery.pbgrd.com/network/logger"] {
	position: absolute;
	height: 0;
	width: 0;
}

/* Temporary until fix is in a released version of elements */
span.u-custom-list-number {
	width: fit-content;
	padding-right: 0.5rem;
	margin-left: -0.5rem;
}

.c-download-pdf-icon-large {
	width: 24px;
	height: 24px;
	vertical-align: middle;
	fill: #025e8d;
}

.c-external-link__icon {
	fill: #025e8d;
	vertical-align: middle;
}

