/**
 * Article Head Styles
 * Article styles for browsers that pass CTM
 */

.c-article-header {
	font-family: $context--font-family-sans;
	margin-bottom: 40px;
}

.c-article-identifiers {
	@include u-list-reset;
	font-size: map-get($article--font-sizes, 'font-size-3');
	line-height: 1.3;
	display: flex;
	flex-wrap: wrap;
	color: map-get($article--grey-palette, 'grey-4');
	margin-left: 0;
	padding-left: 0;
	margin-bottom: $article--vertical-spacing-small;
}

.c-article-identifiers__item {
	border-right: 1px solid map-get($article--grey-palette, 'grey-4');
	margin-right: $article--vertical-spacing-small;
	padding-right: $article--vertical-spacing-small;
	list-style: none;

	&:last-child {
		margin-right: 0;
		padding-right: 0;
		border-right: 0;
	}
}

.c-article-title {
	font-size: map-get($article--font-sizes, 'font-size-6');
	line-height: 1.25; //30px expected line-height size = 30px / font-size: 24px
	margin: 0 0 $article--vertical-spacing-medium;

	@include media-query('md', 'min') {
		font-size: map-get($article--font-sizes, 'font-size-9');
		line-height: 1.2;
	}

	&__super {
		font-size: map-get($article--font-sizes, 'font-size-3');
		line-height: 1.3;
		color: map-get($article--grey-palette, 'grey-3');
		margin: $article--vertical-spacing-medium 0 0 0;
	}
}

.c-article-author-list {
	width: 100%;
	margin: 0 8px 0 0;
	padding: 0;
	display: inline;
	list-style: none;
	font-size: map-get($article--font-sizes, 'font-size-3');
}

.c-article-author-list__item {
	display: inline;
	padding-right: 0;
}

.c-article-author-list svg {
	margin-left: 4px;
}

.c-article-author-list__show-more {
	display: none;
	margin-right: 4px;
}

.js .c-article-author-list__item--hide,
.js .c-article-author-list__show-more,
.c-article-author-list__button {
	display: none;
}

.js .c-article-author-list--long .c-article-author-list__show-more,
.js .c-article-author-list--long + .c-article-author-list__button {
	display: inline;
}

@include media-query('sm', 'max') {
	.js .c-article-author-list__item--hide-small-screen {
		display: none;
	}

	.js .c-article-author-list--short .c-article-author-list__show-more,
	.js .c-article-author-list--short + .c-article-author-list__button {
		display: inline;
	}
}

/* handle list being expanded */
.js .c-article-author-list--expanded .c-article-author-list__show-more {
	display: none !important;
}

.js .c-article-author-list--expanded .c-article-author-list__item--hide-small-screen {
	display: inline !important;
}

.c-article-author-list__button,
.c-button-author-list {
	background: $author-list--button-background-color;
	border: 4px solid $author-list--button-background-color;
	border-radius: $author-list--button-border-radius;
	color: $author-list--button-text-color;
	font-size: map-get($article--font-sizes, 'font-size-2');
	line-height: 1.4;
	padding: 2px 11px 2px 8px;
	text-decoration: none;

	& svg {
		margin: 1px 4px 0 0;
	}

	&:hover {
		background: $author-list--button-hover-background-color;
		border-color: $author-list--button-hover-border-color;
		color: white;
	}
}

.c-article-info-details {
	font-size: map-get($article--font-sizes, 'font-size-3');
	margin-bottom: $article--vertical-spacing-small;
	margin-top: $article--vertical-spacing-medium;

	&__cite-as,
	&__show-history {
		border-left: 1px solid map-get($article--grey-palette, 'grey-4');
		margin-left: $article--vertical-spacing-small;
		padding-left: $article--vertical-spacing-small;
	}
}

.c-article-info-details + .c-article-info-details {
	margin-top: 0;
}

.c-article-metrics-bar {
	display: flex;
	flex-wrap: wrap;
	line-height: 1.3;
	font-size: map-get($article--font-sizes, 'font-size-3');
}

.c-article-metrics-bar__wrapper {
	margin: $article--vertical-spacing-medium 0;
}

.c-article-metrics-bar__item {
	align-items: baseline;
	border-right: 1px solid map-get($article--grey-palette, 'grey-4');
	margin-right: $article--vertical-spacing-small;

	&:last-child {
		border-right: 0;
	}
}

.c-article-metrics-bar__count {
	font-weight: bold;
	margin: 0;
}

.c-article-metrics-bar__label {
	color: map-get($article--grey-palette, 'grey-3');
	font-weight: normal;
	font-style: normal;
	margin: 0 10px 0 5px;
}

.c-article-metrics-bar__details {
	margin: 0;
}

