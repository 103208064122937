.c-author-popup {
	&__author-title {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		grid-column-gap: $article--vertical-spacing-small;
		margin-bottom: $article--vertical-spacing-small;
		margin-right: $article--vertical-spacing-medium;
	}

	&__subheading {
		font-weight: 700;
		margin: 0;
		// extra from magazine but doesnt look necessary
		//float: left;
		//padding-right: $article--vertical-spacing-small;
		//margin-bottom: $article--vertical-spacing-small;
		//margin-top: 4px;
	}

	&__footer-heading {
		font-family: $context--font-family-sans;
		font-size: map-get($article--font-sizes, 'font-size-3');
		font-weight: 700;
		margin: 0;
	}

	&__author-list {
		list-style: none;
		font-size: map-get($article--font-sizes, 'font-size-3');
		padding: 0;
		margin-top: 0;
		clear: both;
		margin-bottom: $article--vertical-spacing-medium;

		> li {
			margin-bottom: $article--vertical-spacing-small;
		}
	}

	&__link {
		font-weight: 700;
		vertical-align: baseline;
		color: $article--link-color;
		text-decoration: none;
	}
}

.c-author-popup .c-article-button {
	color: $article--cta-button-color;
	background: $article--cta-button-background-color;
	border: 1px solid $article--cta-button-border-color;
	border-radius: $article--cta-button-border-radius;
	text-decoration: none;
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-family: $context--font-family-sans;
	display: block;
	width: 100%;
	padding: $article--vertical-spacing-small $article--vertical-spacing-medium;
	text-align: center;
	white-space: nowrap;

	&:visited {
		color: $article--cta-button-color;
	}

	&:hover {
		color: $article--cta-button-hover-color;
		background: $article--cta-button-background-hover-color;
		text-decoration: none;
	}
}

.c-author-popup .c-article-authors-search__list {
	clear: both;

	@include media-query('md', 'min') {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&-item--left {
		@include media-query('md', 'min') {
			flex-basis: 40%;
		}
	}

	&-item--right {
		margin-top: $article--vertical-spacing-medium;
		margin-left: $article--vertical-spacing-small;
		@include media-query('md', 'min') {
			text-align: right;
			flex: 1;
			margin-top: 0;
		}
	}
}

.c-author-popup .c-article-identifiers {
	@include media-query('md', 'min') {
		justify-content: flex-end;
	}
}

// from magazine
.c-author-popup .c-article-authors-search__item,
.c-author-popup .c-article-identifiers {
	font-size: map-get($article--font-sizes, 'font-size-3');
}

// from magazine
.c-author-popup .c-article-identifiers {
	@include media-query('md', 'min') {
		justify-content: flex-end;
	}
}
