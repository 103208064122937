/**
 * Article Save data - article styles for all browsers with lite mode on
 * styles below override any from shared-article/50-components with font-family: $context--font-family-serif;
 */
.save-data h1,
.save-data h2,
.save-data h3,
.save-data .serif,
.save-data .u-serif,
.save-data .c-site-messages--nature-briefing-email-variant .serif,
.save-data .c-site-messages--nature-briefing-email-variant.serif,
.save-data .c-article-main-column,
.save-data .c-article-author-institutional-author__sub-division,
.save-data .c-article-fullwidth-content,
.save-data .c-article-satellite-article-link,
.save-data .c-code-block__heading,
.save-data .c-blockquote__body,
.save-data .c-article-equation__number,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-satellite-article-link,
.save-data .c-article-figure-description,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-table-container,
.save-data .c-reading-companion__reference-citation,
.save-data .c-reading-companion__figure-title {
	font-family: $context--font-family-serif-save-data
}
