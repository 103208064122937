/**
 * Article Companion
 * Article styles for browsers that pass CTM
 */

.c-reading-companion {
	clear: both;
	min-height: 389px; // stimated average height with a small reading companion
}

.c-reading-companion .u-lazy-ad-wrapper {
	background-color: transparent !important;
	min-height: 250px; // MPU size definition. This shouldn't change

	.div-gpt-ad {
		height: 100%;
	}
}


.c-reading-companion__heading {
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-3');
	font-weight: bold;
	padding: 0 $article--vertical-spacing-medium $article--vertical-spacing-medium;
	margin: 0;
}

.c-reading-companion__sticky {
	max-width: 389px;
	&--stuck {
		margin: 0 0 $article--vertical-spacing-medium;
		position: fixed;
		top: 50px;
	}
}

.c-reading-companion__scroll-pane {
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0 0 0;
	min-height: 200px;
}

.c-reading-companion__tabs {
	font-size: map-get($article--font-sizes, 'font-size-3');
	list-style: none;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0 0 $article--vertical-spacing-small;
	padding: 0;

	> li {
		flex-grow: 1;
	}
}

.c-reading-companion__tab {
	color: color('blue');
	border: solid map-get($article--grey-palette, 'grey-6');
	border-width: 1px 1px 1px 0;
	background-color: map-get($article--grey-palette, 'grey-7');
	padding: 8px 8px 8px 15px;
	text-align: left;
	font-size: map-get($article--font-sizes, 'font-size-3');
	width: 100%;

	&:focus {
		text-decoration: none;
		outline-offset: -4px;
	}
}

.c-reading-companion__tabs li:first-child .c-reading-companion__tab {
	border-left-width: 1px;
}

.c-reading-companion__tab--active {
	color: map-get($article--grey-palette, 'grey-1');
	background-color: $article--page-background-color;
	border-bottom: 1px solid $article--page-background-color;
	font-weight: bold;
}

.c-reading-companion--highlighted {
	-webkit-animation: highlight 2s ease;
	animation: highlight 2s ease;
}

@-webkit-keyframes highlight {
	0% {
		background-color: #ff9;
	}

	60% {
		background-color: #ff9;
	}

	100% {
		background-color: #fff;
	}
}

@keyframes highlight {
	0% {
		background-color: #ff9;
	}

	60% {
		background-color: #ff9;
	}

	100% {
		background-color: #fff;
	}
}

.c-reading-companion__sections-list,
.c-reading-companion__figures-list,
.c-reading-companion__references-list {
	list-style: none;
	padding: 0;
	min-height: 389px;
}

.c-reading-companion__references-list--numeric {
	list-style: decimal inside;
}

.c-reading-companion__sections-list {
	margin: 0 0 $article--vertical-spacing-small;
	min-height: 50px;
}

.c-reading-companion__section-item {
	font-size: map-get($article--font-sizes, 'font-size-3');
	padding: 0;

	a {
		display: block;
		padding: $article--vertical-spacing-small 0 $article--vertical-spacing-small $article--vertical-spacing-medium;
		line-height: 1.5;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&--active {
		a {
			white-space: normal;
			border-left: 4px solid map-get($article--grey-palette, 'grey-6');
			padding-left: 12px;
			color: map-get($article--grey-palette, 'grey-1');
		}
	}

	.c-article-section__title-number {
		display: none;
	}
}

.c-reading-companion__figure-item {
	padding: $article--vertical-spacing-medium $article--vertical-spacing-small $article--vertical-spacing-medium 0;
	border-top: 1px solid map-get($article--grey-palette, 'grey-6');
	font-size: map-get($article--font-sizes, 'font-size-3');

	&:first-child {
		border-top: none;
		padding-top: $article--vertical-spacing-small;
	}
}

.c-reading-companion__reference-item {
	padding: $article--vertical-spacing-small $article--vertical-spacing-small $article--vertical-spacing-small $article--vertical-spacing-medium;
	border-top: 1px solid map-get($article--grey-palette, 'grey-6');
	font-size: map-get($article--font-sizes, 'font-size-3');

	&:first-child {
		border-top: none;
	}

	a {
		word-break: break-word;
	}
}

.c-reading-companion__reference-citation {
	display: inline;
}

.c-reading-companion__reference-links {
	list-style: none;
	text-align: right;
	margin: $article--vertical-spacing-small 0 0;
	padding: 0;
	font-weight: bold;
	font-size: map-get($article--font-sizes, 'font-size-1');

	> a {
		display: inline-block;
		padding-left: $article--vertical-spacing-small;
	}

	> a:first-child {
		display: inline-block;
		padding-left: 0;
	}
}

.c-reading-companion__figure-title {
	@include u-h4();
	display: block;
	margin: 0 0 $article--vertical-spacing-small;
}

.c-reading-companion__figure-links {
	margin: $article--vertical-spacing-small 0 0;
	display: flex;
	justify-content: space-between;
}

.c-reading-companion__figure-links > a {
	display: flex;
	align-items: center;
}

.c-reading-companion__figure-item picture:last-child {
	margin-bottom: $article--vertical-spacing-medium;
}

.c-reading-companion__figure-full-link {
	svg {
		height: 0.8em;
		margin-left: spacing(2);
	}
}

.c-reading-companion__panel {
	display: none;
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}

.c-reading-companion__panel--active {
	display: block;
}

.c-reading-companion__return {
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;

	&:focus {
		position: static;
		width: auto;
		height: auto;
		padding: 0 4px;
	}
}

.c-behind-the-paper {
	display: none;
}
