@charset "UTF-8";
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
.u-h1 {
  font-size: 2rem;
  font-weight: 700;
}

.u-h2, .c-card--major .c-card__title {
  font-size: 1.5rem;
  font-weight: 700;
}

.u-h3, .c-article-editorial-summary__container .c-article-editorial-summary__article-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.u-h4, .c-card__title, .c-reading-companion__figure-title {
  font-size: 1.125rem;
  font-weight: 700;
}

/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
.u-h1 {
  font-size: 2rem;
  font-weight: 700;
}

.u-h2, .c-card--major .c-card__title {
  font-size: 1.5rem;
  font-weight: 700;
}

.u-h3, .c-article-editorial-summary__container .c-article-editorial-summary__article-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.u-h4, .c-card__title, .c-reading-companion__figure-title {
  font-size: 1.125rem;
  font-weight: 700;
}

/**
 * Colour palette
 *
 */
/**
 * Color and gray scales
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 *  Button settings
 *  Springer
 *
 */
/**
 * Generates utility classnames for base and each breakpoint
 *
 * Example:
 * @include class-stack('u-text-right') would output
 * u-text-right { @content }
 * @include media-query('xs') { u-text-right-at-lt-xs { @content } }
 *
 */
/**
 * Default link style
 *
 */
/**
 * Alternative link color
 *
 */
/**
 * Deemphasised link color
 *
 */
/**
 * Unvisited
 *
 */
/**
 * Link like
 * Style non-links to look like links
 * Remember to add aria
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
button {
  line-height: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 4px solid #ffcc00;
}

input[type=file]:focus-within {
  outline: 4px solid #ffcc00;
}

button:disabled:focus,
input:disabled:focus,
select:disabled:focus,
textarea:disabled:focus {
  outline: none;
}

label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

/*
 * Layout
 * Universal layout styles
 */
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
  color: #333333;
  line-height: 1.6180339888;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  max-width: 100%;
  min-height: 100%;
  background: white;
  font-size: 1.125rem; /* fixes chrome rems bug - http://stackoverflow.com/questions/20099844/chrome-not-respecting-rem-font-size-on-body-tag */
}

figure {
  margin: 0;
}

body,
div,
dl,
dt,
dd,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
th,
td {
  margin: 0;
  padding: 0;
}

abbr[title] {
  text-decoration: none;
}

[contenteditable]:focus,
[tabindex="0"]:focus {
  outline: 4px solid #ffcc00;
}

a {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
a.visited, a:visited {
  color: #8e2555;
}
a.hover, a:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
a.active, a:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
a.focus, a:focus {
  outline: 4px solid #ffcc00;
}
a > img {
  vertical-align: middle;
}

table {
  border: 1px solid rgb(165.75, 165.75, 165.75);
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  font-size: 14px;
  border: 1px solid rgb(165.75, 165.75, 165.75);
  padding: 0.3em 0.6em;
  vertical-align: top;
}

th {
  background: rgb(229.5, 229.5, 229.5);
  text-align: left;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  color: #1b3051;
  line-height: 1.4;
}
h1 a {
  text-decoration: none;
}
h1 a:hover, h1 a:active {
  color: #004b83;
}
h1 a:visited {
  color: #8e2555;
}
h1 a:hover {
  text-decoration: underline;
}
h1 a:active {
  color: rgb(0, 96.6984732824, 168.9);
  text-decoration: none;
}
html.webfonts-loaded h1 {
  font-family: "Europa", "Trebuchet MS";
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  color: #1b3051;
  line-height: 1.4;
  margin-bottom: 0.5em;
}
h2 a {
  text-decoration: none;
}
h2 a:hover, h2 a:active {
  color: #004b83;
}
h2 a:visited {
  color: #8e2555;
}
h2 a:hover {
  text-decoration: underline;
}
h2 a:active {
  color: rgb(0, 96.6984732824, 168.9);
  text-decoration: none;
}
html.webfonts-loaded h2 {
  font-family: "Europa", "Trebuchet MS";
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 18px;
  color: #1b3051;
  font-style: normal;
  line-height: 1.4;
  margin-bottom: 0.7em;
}
h3 a {
  text-decoration: none;
}
h3 a:hover, h3 a:active {
  color: #004b83;
}
h3 a:visited {
  color: #8e2555;
}
h3 a:hover {
  text-decoration: underline;
}
h3 a:active {
  color: rgb(0, 96.6984732824, 168.9);
  text-decoration: none;
}
html.webfonts-loaded h3 {
  font-family: "Europa", "Trebuchet MS";
}

h4 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 18px;
  color: #1b3051;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0.7em;
}
h4 a {
  text-decoration: none;
}
h4 a:hover, h4 a:active {
  color: #004b83;
}
h4 a:visited {
  color: #8e2555;
}
h4 a:hover {
  text-decoration: underline;
}
h4 a:active {
  color: rgb(0, 96.6984732824, 168.9);
  text-decoration: none;
}
html.webfonts-loaded h4 {
  font-family: "Europa", "Trebuchet MS";
}

h5,
h6 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
h5 a.active, h5 a:active, h5 a.hover, h5 a:hover,
h6 a.active,
h6 a:active,
h6 a.hover,
h6 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/* Basic lists should be aligned to the left */
ul:not([class]),
ol:not([class]) {
  /* Allow for bullet itself */
  padding-left: 0.9em;
}

dd {
  margin: 0;
}

cite {
  font-style: normal;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

blockquote {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Europa";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Europa-Regular"), url("/static/app-bmc/fonts/europa-regular.woff2") format("woff2"), url("/static/app-bmc/fonts/europa-regular.woff") format("woff");
}
@font-face {
  font-family: "Europa";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Europa-Bold"), url("/static/app-bmc/fonts/europa-bold.woff2") format("woff2"), url("/static/app-bmc/fonts/europa-bold.woff") format("woff");
}
/* latin-ext */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Noto Serif"), local("NotoSerif"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFkWaDq8fMVxMw.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Noto Serif"), local("NotoSerif"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFcWaDq8fMU.woff2") format("woff2"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Iaw1J5X9T9RW6j9bNfFcWbg.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("Noto Serif Italic"), local("NotoSerif-Italic"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImZDC7XsdBMr8R.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("Noto Serif Italic"), local("NotoSerif-Italic"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImajC7XsdBMg.woff2") format("woff2"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Kaw1J5X9T9RW6j9bNfFImajC9.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Noto Serif Bold"), local("NotoSerif-Bold"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfRmece9LOocoDg.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Noto Serif";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Noto Serif Bold"), local("NotoSerif-Bold"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfReece9LOoc.woff2") format("woff2"), url("https://fonts.gstatic.com/s/notoserif/v6/ga6Law1J5X9T9RW6j9bNdOwzfReedw.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*
HAVE ADDED THE ARTICLE COLOUR STYLES HERE TEMPORARILY
*/
/*
HAVE ADDED THE ARTICLE COLOUR STYLES HERE TEMPORARILY AS BMC_CONTEXT OVERRIDES IT
*/
/**
 * Settings
 * Article component configuration
 */
/* Spacing defined by the 8-Point grid - https://spec.fm/specifics/8-pt-grid */
/**
 * Links
 * New underline styles to apply at the shared article level
 */
a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.08em;
}
a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.08em;
}

p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/**
 * Card
 * Default branding for component
 */
/**
 * Popup
 * Default branding for component
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
.u-h1 {
  font-size: 2.25rem;
  font-weight: 700;
}

.u-h2, .c-card--major .c-card__title {
  font-size: 1.75rem;
  font-weight: 700;
}

.u-h3, .c-article-editorial-summary__container .c-article-editorial-summary__article-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.u-h4, .c-card__title, .c-reading-companion__figure-title {
  font-size: 1.25rem;
  font-weight: 700;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/**
THE CONTENT BELOW IS ADDED HERE TEMPORARILY TO ALLOW LOCAL DEVELOPMENT
- should this move to a new file bringing it inline with other imprints lib/frontend/shared/scss/shared-article/10-settings/bmc.scss
*/
/**
 * Settings
 * Article component configuration
 */
/*
HAVE ADDED THE ARTICLE KEYFRAME SETTINGS HERE TEMPORARILY
*/
/**
 * Article Enhanced
 * Article styles for browsers that pass CTM
 */
/**
 * Article Head Styles
 * Article styles for browsers that pass CTM
 */
.c-article-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 40px;
}

.c-article-identifiers {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  color: rgb(110.5, 110.5, 110.5);
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 8px;
}

.c-article-identifiers__item {
  border-right: 1px solid rgb(110.5, 110.5, 110.5);
  margin-right: 8px;
  padding-right: 8px;
  list-style: none;
}
.c-article-identifiers__item:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}

.c-article-title {
  font-size: 1.5rem;
  line-height: 1.25;
  margin: 0 0 16px;
}
@media only screen and (min-width: 768px) {
  .c-article-title {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
.c-article-title__super {
  font-size: 1rem;
  line-height: 1.3;
  color: rgb(97.75, 97.75, 97.75);
  margin: 16px 0 0 0;
}

.c-article-author-list {
  width: 100%;
  margin: 0 8px 0 0;
  padding: 0;
  display: inline;
  list-style: none;
  font-size: 1rem;
}

.c-article-author-list__item {
  display: inline;
  padding-right: 0;
}

.c-article-author-list svg {
  margin-left: 4px;
}

.c-article-author-list__show-more {
  display: none;
  margin-right: 4px;
}

.js .c-article-author-list__item--hide,
.js .c-article-author-list__show-more,
.c-article-author-list__button {
  display: none;
}

.js .c-article-author-list--long .c-article-author-list__show-more,
.js .c-article-author-list--long + .c-article-author-list__button {
  display: inline;
}

@media only screen and (max-width: 539px) {
  .js .c-article-author-list__item--hide-small-screen {
    display: none;
  }
  .js .c-article-author-list--short .c-article-author-list__show-more,
  .js .c-article-author-list--short + .c-article-author-list__button {
    display: inline;
  }
}
/* handle list being expanded */
.js .c-article-author-list--expanded .c-article-author-list__show-more {
  display: none !important;
}

.js .c-article-author-list--expanded .c-article-author-list__item--hide-small-screen {
  display: inline !important;
}

.c-article-author-list__button,
.c-button-author-list {
  background: #ebf1f5;
  border: 4px solid #ebf1f5;
  border-radius: 20px;
  color: #666;
  font-size: 0.875rem;
  line-height: 1.4;
  padding: 2px 11px 2px 8px;
  text-decoration: none;
}
.c-article-author-list__button svg,
.c-button-author-list svg {
  margin: 1px 4px 0 0;
}
.c-article-author-list__button:hover,
.c-button-author-list:hover {
  background: #173962;
  border-color: transparent;
  color: white;
}

.c-article-info-details {
  font-size: 1rem;
  margin-bottom: 8px;
  margin-top: 16px;
}
.c-article-info-details__cite-as, .c-article-info-details__show-history {
  border-left: 1px solid rgb(110.5, 110.5, 110.5);
  margin-left: 8px;
  padding-left: 8px;
}

.c-article-info-details + .c-article-info-details {
  margin-top: 0;
}

.c-article-metrics-bar {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3;
  font-size: 1rem;
}

.c-article-metrics-bar__wrapper {
  margin: 16px 0;
}

.c-article-metrics-bar__item {
  align-items: baseline;
  border-right: 1px solid rgb(110.5, 110.5, 110.5);
  margin-right: 8px;
}
.c-article-metrics-bar__item:last-child {
  border-right: 0;
}

.c-article-metrics-bar__count {
  font-weight: bold;
  margin: 0;
}

.c-article-metrics-bar__label {
  color: rgb(97.75, 97.75, 97.75);
  font-weight: normal;
  font-style: normal;
  margin: 0 10px 0 5px;
}

.c-article-metrics-bar__details {
  margin: 0;
}

/**
 * Article Layout
 * Article styles for general article column widths
 */
.c-article-main-column {
  font-family: Georgia, Palatino, serif;
  margin-right: 8.6%;
  width: 60.2%;
}
@media only screen and (max-width: 1023px) {
  .c-article-main-column {
    margin-right: 0;
    width: 100%;
  }
}

.c-article-extras {
  float: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  width: 31.2%;
}
@media only screen and (max-width: 1023px) {
  .c-article-extras {
    display: none;
  }
}

/**
 * Article Enhanced
 * Article styles for browsers that pass CTM
 */
/* reset list styles */
.c-article-author-institutional-author,
.c-article-author-information__list,
.c-article-references,
.c-article-author-affiliation__list,
.c-article-author-authors-search,
.c-article-supplementary__list,
.c-article-further-reading__list,
.c-article-footnote--listed,
.c-article-references__nav,
.c-article-change-list,
.c-section-author-list {
  list-style: none;
  padding: 0;
}

.c-section-author-list {
  margin-bottom: 16px;
}

.c-section-author-list > li {
  display: inline;
  font-weight: bold;
}

.c-article-associated-content__container .c-article-associated-content__title,
.c-article-section__title {
  border-bottom: 2px solid rgb(212.5, 212.5, 212.5);
  font-size: 1.25rem;
  padding-bottom: 8px;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .c-article-associated-content__container .c-article-associated-content__title,
  .c-article-section__title {
    font-size: 1.5rem;
    line-height: 1.24;
  }
}

.c-article-associated-content__container .c-article-associated-content__title {
  margin-bottom: 8px;
}

.c-article-body p,
.c-article-body ul,
.c-article-body ol,
.c-article-body dl {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-references p,
.c-bibliographic-information__column p,
.c-bibliographic-information__column button {
  margin-bottom: 16px;
}

.c-article-section {
  clear: both;
}

.c-article-section__content {
  padding-top: 8px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1023px) {
  .c-article-section__content {
    padding-left: 0;
  }
}

.c-article-section__content--separator {
  border-top: 2px solid rgb(212.5, 212.5, 212.5);
  padding-top: 40px;
}

.c-article__pill-button {
  padding: 8px 16px 8px 20px;
  background-color: white;
  border: 4px solid #bcd2dc;
  border-radius: 20px;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.c-article__pill-button:hover {
  background-color: #0067c5;
  color: white;
  text-decoration: none;
}

.c-article__pill-button:focus {
  outline: 0;
  box-shadow: 0 0 0 3px #fece3e;
  text-decoration: none;
}

.c-article__pill-button:active,
.c-article__pill-button:hover {
  box-shadow: 0 0 0 0;
}

.c-article__pill-button svg {
  height: 0.8em;
  margin-left: 2px;
}

.c-article-section__illustration {
  font-size: 0.875rem;
  float: none;
  margin: 0 auto 24px;
  padding: 0;
}

@media only screen and (min-width: 540px) {
  .c-article-section__illustration-right {
    margin: 8px 0 16px 24px;
    float: right;
  }
}

.c-article__sub-heading {
  font-size: 1.125rem;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  line-height: 1.3;
  color: #222222;
  margin: 24px 0 8px;
}
@media only screen and (min-width: 768px) {
  .c-article__sub-heading {
    font-size: 1.5rem;
    line-height: 1.24;
  }
}
.c-article__sub-heading--small {
  font-size: 1.125rem;
  font-weight: bold;
}

.c-article__sub-heading:first-child {
  margin-top: 0;
}

.c-article__sub-heading--divider::before {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  margin-top: 40px;
  margin-bottom: 16px;
  background: #222222;
}

.c-article-body .c-article-access-provider,
.c-notes {
  padding: 8px 16px;
  border: 1px solid rgb(212.5, 212.5, 212.5);
  border-left: none;
  border-right: none;
  margin: 24px 0;
}
.c-article-body .c-article-access-provider__text,
.c-notes__text {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #555555;
  text-align: center;
}

.c-article-change-list__item > ins {
  text-decoration: none;
}

.c-article-change-list__details,
.c-article-change-list__details > p:first-child {
  display: inline;
}

.c-article-references__heading {
  font-weight: bold;
  font-size: 1.05rem;
  margin: 24px 0 4px;
}

.c-article-references__item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.c-article-references__item::before {
  content: attr(data-counter);
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: right;
}
@media only screen and (max-width: 1023px) {
  .c-article-references__item::before {
    font-size: 1.125rem;
    line-height: inherit;
  }
}

.c-article-references__item > p.c-article-references__text,
.c-article-references__item > h3 {
  flex: 1;
  padding-left: 8px;
}

.c-article-references__text {
  margin-bottom: 8px;
}

.c-article-references__links {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-article-references__links a {
  padding-left: 8px;
}

.c-article-references__links a:first-child {
  padding-left: 0;
}

.c-article-references__download {
  text-align: right;
}

.c-article-references__download > a {
  font-size: 1rem;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-article-references__download svg {
  margin-left: 4px;
  margin-top: 2px;
}

.c-article-author-information__list {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-author-information__authors-list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: bold;
}

.c-article-author-information__item {
  margin-bottom: 16px;
}

.c-article-author-information__subtitle {
  font-weight: 700;
  margin: 0 0 8px 0;
}

.c-article-author-affiliation__list > li {
  margin-bottom: 16px;
}

.c-article-body .c-article-author-affiliation__address {
  color: inherit;
  font-weight: 700;
  margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.c-article-body .c-article-author-affiliation__authors-item {
  display: inline;
  margin-left: 0;
}

.c-article-authors__list {
  padding: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-authors-search {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-authors-search__title {
  color: rgb(97.75, 97.75, 97.75);
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.05rem;
}

.c-article-authors-search__item {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-article-authors-search__text {
  margin: 0;
}

.c-article-author-institutional-author {
  margin-top: 0;
  margin-bottom: 24px;
}

.c-article-author-institutional-author__name {
  font-size: 1.05rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.c-article-author-institutional-author__author-list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
}
.c-article-author-institutional-author__author-list--popup {
  clear: both;
  margin-bottom: 16px;
}

.c-article-author-institutional-author__author-name {
  display: inline;
  list-style: none;
}

.c-article-author-institutional-author__sub-division {
  line-height: 1.76;
  margin: 0;
}

.c-article-author-institutional-author__author-list .c-article-author-institutional-author__author-list .c-article-author-institutional-author__sub-division {
  color: rgb(97.75, 97.75, 97.75);
}

.c-article-supplementary {
  margin-bottom: 24px;
}
.c-article-supplementary__item {
  margin-bottom: 24px;
  position: relative;
}
.c-article-supplementary__item > span {
  width: 40px;
  line-height: 1.4;
  font-weight: 400;
  text-align: right;
  position: absolute;
  font-size: 1.5rem;
}
.c-article-supplementary__external {
  margin-bottom: 16px;
}
.c-article-supplementary__description > * {
  margin-bottom: 0;
}

.c-article-fullwidth-content__infographic {
  position: relative;
  margin-bottom: 24px;
  padding: 0;
}
.c-article-fullwidth-content__image {
  border: 4px solid #dae5ea;
  position: relative;
}
.c-article-fullwidth-content__image img {
  max-width: 100%;
}
.c-article-fullwidth-content__caption {
  margin: 1px 0 20px 0;
}

.c-article-supplementary__title {
  margin: 0 0 8px;
  line-height: 1.5;
}

.c-article-supplementary--enumerated .c-article-supplementary__title,
.c-article-supplementary--enumerated .c-article-supplementary__description {
  margin-left: 50px;
}

.c-article-further-reading__list {
  margin: 0;
}

.c-article-further-reading__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  margin-bottom: 16px;
  position: relative;
}
.c-article-further-reading__item:last-child {
  border: none;
}

.c-article-further-reading__title {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.c-article-further-reading__authors-list {
  padding: 0;
  list-style: none;
  margin: 8px 0 0 0;
  font-size: 0.813rem;
  line-height: 1.4;
  color: rgb(97.75, 97.75, 97.75);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-article-further-reading__authors-list li {
  display: inline;
}

.c-article-further-reading__journal-title {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
  margin-bottom: 24px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-article-license {
  font-size: 1rem;
  margin-bottom: 24px;
}

.c-article-license__link {
  float: left;
  margin-right: 10px;
}

.c-article-license__badge {
  margin-top: 8px;
}

.c-article-subject-list {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 24px;
}

.c-article-subject-list__subject {
  background-color: rgb(225.25, 225.25, 225.25);
  border-radius: 20px;
  padding: 4px 10px;
  font-weight: 700;
  margin-right: 15px;
  margin-bottom: 16px;
  flex: 0 1 auto;
}

.c-article-satellite-article-link {
  font-family: Georgia, Palatino, serif;
}

.c-article-compound-container {
  margin-bottom: 16px;
}

.c-article-compound-details {
  display: inline-block;
  margin-bottom: 16px;
}

.c-article-compound-details p {
  margin-bottom: 16px;
}

.c-article-compound-details__image {
  margin-bottom: 16px;
  max-width: 100%;
}

.c-article-compound-details__links {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.875rem;
}

.c-article-compound-details__synonym {
  margin-bottom: 16px;
}

.c-article-compound-details__synonym-list {
  padding: 0;
  list-style: none;
}

.c-article-compound-details__synonym-list > li {
  display: inline;
}

.c-bibliographic-information {
  display: flex;
  padding-top: 8px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information {
    padding-top: 0;
    display: block;
  }
}
.c-bibliographic-information__value {
  font-size: 1rem;
  display: block;
}

.c-bibliographic-information__column:first-child {
  width: 81px;
  flex: 0 0 81px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__column:first-child {
    width: 100%;
  }
}

.c-bibliographic-information__column:last-child {
  flex: 1;
}

.c-bibliographic-information__column--border {
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  margin-right: 24px;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__column--border {
    border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-right: 0;
    margin-right: 0;
  }
}

.c-bibliographic-information__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 32px;
}
.c-bibliographic-information__list-item {
  flex: 0 0 calc((100% - 64px) / 3);
  box-sizing: border-box;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
@media only screen and (max-width: 1023px) {
  .c-bibliographic-information__list-item {
    flex: 0 0 100%;
  }
}
.c-bibliographic-information__list-item h4 {
  font-style: normal;
  font-size: 1rem;
}
.c-bibliographic-information__list-item p {
  margin-bottom: 0;
}
.c-bibliographic-information__list-item:last-child {
  padding-right: 0;
}
.c-bibliographic-information__list-item--full-width {
  flex: 0 0 100%;
}

.c-bibliographic-information__citation,
.c-bibliographic-information__download-citation {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin-bottom: 8px;
}

.c-bibliographic-information__download-citation {
  margin-bottom: 24px;
}
.c-bibliographic-information__download-citation svg {
  margin-left: 4px;
  margin-top: 2px;
}

.c-code-block {
  padding: 20px;
  margin: 0 0 24px 0;
  border: 1px solid #f2f2f2;
  font-family: monospace;
}

.c-code-block__heading {
  margin-bottom: 16px;
  font-weight: normal;
}

.c-code-block__line {
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
}

.c-stack {
  display: inline-table;
  vertical-align: top;
}

.c-stack sup,
.c-stack sub {
  display: table-row;
  line-height: 1;
}

.c-literal {
  font-family: monospace;
}

.c-blockquote {
  border-left: 4px solid rgb(212.5, 212.5, 212.5);
  padding-left: 16px;
  margin: 24px 0;
  position: relative;
}
.c-blockquote::before, .c-blockquote::after {
  content: "";
  display: table;
}
.c-blockquote::after {
  clear: both;
}
.c-blockquote:before, .c-blockquote:after {
  height: 7px;
  border-left: 4px solid white;
  position: absolute;
  margin-left: -4px;
  left: 0;
}
.c-blockquote:before {
  top: 0;
}
.c-blockquote:after {
  bottom: 0;
}
.c-blockquote:first-child {
  margin-top: 0;
}
.c-blockquote__body > * {
  margin-bottom: 0;
}
.c-blockquote--motto {
  border-color: rgb(225.25, 225.25, 225.25);
}

.c-book-review {
  margin-top: 40px;
  margin-bottom: 24px;
  padding: 30px 0 20px 20px;
  border-bottom: 2px solid;
  border-top: 2px solid;
  border-image: linear-gradient(to right, white, white 23%, #f2f2f2 0, #f2f2f2 74%, white 23%, white) 10;
  font-size: 0.813rem;
}
.c-book-review__title {
  font-weight: 700;
  font-size: 1.05rem;
  line-height: 1.3;
}
.c-book-review__image {
  margin: 10px 30px 10px 0;
  float: left;
}
.c-book-review img {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
.c-book-review__list {
  padding: 0;
  list-style: none;
  display: block;
  visibility: visible;
  margin-top: 0;
  margin-bottom: 10px;
}
.c-book-review__list:last-child {
  margin-bottom: 24px;
}
.c-book-review__item {
  display: inline;
}
.c-book-review__additional-info {
  margin-bottom: 10px;
}

.c-key-step {
  border: 1px solid #0067c5;
  margin-bottom: 24px;
  padding: 15px 15px 6px 15px;
}

.c-key-step--important {
  border-color: #cc4b14;
}

.c-key-step__heading {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
  color: #0067c5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  padding-left: 20px;
  text-transform: uppercase;
}

.c-key-step--important > .c-key-step__heading {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'109.508%2034.751%2016%2016'%20enable-background%3D'new%20109.508%2034.751%2016%2016'%3E%3Cpath%20fill%3D'%23AB5306'%20d%3D'M125.382%2048.933l-7.35-12.914c-.314-.526-.734-.526-1.05%200l-7.35%2012.913c-.314.525%200%20.945.526.945h14.7c.524%200%20.84-.42.524-.945zm-8.61-9.555h1.47c.21%200%20.316.105.316.315l-.21%205.774c0%20.21-.105.315-.315.315h-1.05c-.21%200-.315-.105-.315-.315l-.21-5.774c0-.21.104-.315.315-.315zm.736%209.45c-1.05%200-1.155-.525-1.155-1.05%200-.63.21-1.05%201.155-1.05.945%200%201.155.42%201.155%201.05%200%20.525-.21%201.05-1.155%201.05z'%2F%3E%3C%2Fsvg%3E");
  color: #cc4b14;
}

.c-key-step__body {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 1rem;
  margin: 0;
}

.c-key-step__body > p {
  margin-bottom: 16px;
}

.c-troubleshooting-step {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'125%2025%2016%2016'%20enable-background%3D'new%20125%2025%2016%2016'%3E%3Cpath%20fill%3D'%23215F90'%20d%3D'M133%2025c-4.374%200-8%203.626-8%208s3.626%208%208%208%208-3.626%208-8-3.626-8-8-8zm1.173%2012.48c0%20.107%200%20.32-.32.32h-1.707c-.213%200-.32-.106-.32-.32v-5.76c0-.213.106-.32.32-.32h1.707c.213%200%20.32.213.32.32v5.76zM133%2030.333c-1.067%200-1.173-.534-1.173-1.067%200-.64.213-1.066%201.173-1.066s1.173.426%201.173%201.066c0%20.534-.106%201.067-1.173%201.067z'%2F%3E%3C%2Fsvg%3E") no-repeat 0 50%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  padding-left: 20px;
  text-transform: uppercase;
}

.c-timing-step {
  color: rgb(97.75, 97.75, 97.75);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin: 0 0 8px 0;
}

.c-article-box {
  border: 2px solid #dae5ea;
  margin: 0 0 24px 0;
  padding: 0;
}

.c-article-box__container {
  margin: 0;
  overflow: hidden;
  padding: 20px 20px 30px 20px;
}

.c-article-box__container-title {
  margin-bottom: 8px;
  font-weight: bold;
}

.c-article-box__content > * {
  margin-bottom: 0;
}

.c-article-box__content > * + * {
  margin-top: 24px;
}

.c-article-box__controls {
  position: relative;
  margin-top: -56px;
}

.c-article-box__controls button {
  display: block;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: #dae5ea;
  color: #0067c5;
  border: none;
  padding: 10px 20px 6px 20px;
  width: 100%;
  cursor: pointer;
}
.c-article-box__controls button:hover, .c-article-box__controls button:focus {
  text-decoration: underline;
}

.c-article-box__button-text:after {
  content: "";
  width: 20px;
  height: 10px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'12'%20height%3D'7'%20viewBox%3D'0%200%2012%207'%3E%3Cpath%20fill%3D'%230067C5'%20d%3D'M9.6%200l-3.6%204.3-3.6-4.3h-2.4l6%207%206-7h-2.4z'%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: inline-block;
}

.c-article-box--expanded .c-article-box__button-text:after {
  transform: rotate(180deg);
}

.c-article-box__fade {
  height: 56px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.38) 25%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0.85) 75%, #fff 100%);
}

.c-article-box--expanded .c-article-box__controls {
  margin-top: 0;
}

.c-article-box--expanded .c-article-box__fade {
  display: none;
}

.c-article-footnote {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  margin-bottom: 24px;
  padding: 20px 30px;
}
.c-article-footnote p {
  margin-bottom: 0;
}
.c-article-footnote p + p {
  margin-top: 16px;
}

.c-article-footnote--listed {
  border: none;
  padding: 0;
}
.c-article-footnote--listed__item {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.c-article-footnote--listed__content {
  flex: 1;
  padding-left: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.c-article-footnote--listed__item::before {
  content: attr(data-counter);
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: right;
}

.c-article-share-box {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 24px;
}
.c-article-share-box__title {
  font-weight: 700;
  margin-bottom: 8px;
}
.c-article-share-box__description {
  font-size: 1rem;
  margin-bottom: 8px;
}
.c-article-share-box__no-sharelink-info {
  font-size: 0.813rem;
  font-weight: 700;
  padding-top: 4px; /* make equal height with share button to prevent redraw */
  margin-bottom: 24px;
}
.c-article-share-box__only-read-input {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  display: inline-block;
  box-sizing: content-box;
  height: 24px;
  margin-bottom: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 8px 10px;
}
.c-article-share-box__only-read-input--highlighted {
  animation: "transparent-to-yellow" 1.5s ease;
}
@keyframes transparent-to-yellow {
  0% {
    background: transparent;
  }
  25% {
    background: #ffff99;
  }
  75% {
    background: #ffff99;
  }
  100% {
    background: transparent;
  }
}
.c-article-share-box__additional-info {
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
}
.c-article-share-box__button {
  background: white;
  border: 1px solid #0067c5;
  box-sizing: content-box;
  color: #0067c5;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 8px;
  padding: 8px 20px;
  text-align: center;
}
.c-article-share-box__button--link-like {
  background-color: transparent;
  border: none;
  color: #0067c5;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 8px;
  margin-left: 10px;
}

.c-pan-zoom-controls {
  text-align: center;
  background: #dae5ea;
  border-bottom: 6px solid #dae5ea;
  padding: 8px 10px;
}
.c-pan-zoom-controls ul {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0;
}
.c-pan-zoom-controls li {
  border-left: 1px solid white;
  padding: 4px;
}
.c-pan-zoom-controls li:first-child {
  border-left: none;
}
.c-pan-zoom-controls button {
  border: none;
  padding: 0 10px;
  background-color: transparent;
  color: #0067c5;
}
.c-pan-zoom-controls button:hover,
.c-pan-zoom-controls button:focus {
  text-decoration: underline;
}

.c-pan-zoom-controls__zoom-in button,
.c-pan-zoom-controls__zoom-out button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px auto;
  width: 16px;
  height: 16px;
  padding: 4px 20px;
}

.c-pan-zoom-controls__zoom-in button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%230067C5'%20d%3D'M6%200h4v16h-4v-16zm-6%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
}

.c-pan-zoom-controls__zoom-out button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20width%3D'16'%20height%3D'16'%20viewBox%3D'0%200%2016%2016'%3E%3Cpath%20fill%3D'%230067C5'%20d%3D'M0%206h16v4h-16v-4z'%2F%3E%3C%2Fsvg%3E");
}

.c-article-equation {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
}
.c-article-equation .MathJax_Display > .MathJax {
  display: block !important;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.c-article-equation #MathJax_Zoom {
  background-color: #f7fbfe;
  border: 1px solid #97bfd8;
  border-radius: 2px;
  box-shadow: 0 0 5px 2px rgba(51, 51, 51, 0.2);
}
.c-article-equation__content {
  display: block;
  text-align: left;
  vertical-align: middle;
}
@media only screen and (min-width: 768px) {
  .c-article-equation__content {
    display: table-cell;
    width: 90%;
  }
}
.c-article-equation__content > .MathJax {
  display: block !important;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.c-article-equation__number {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: left;
  vertical-align: middle;
  margin: 12px 0 16px;
}
@media only screen and (min-width: 768px) {
  .c-article-equation__number {
    display: table-cell;
    width: 10%;
    white-space: nowrap;
    text-align: right;
    padding-left: 16px;
  }
}

.MathJax_SVG svg {
  max-width: 100%;
}

#MathJax_Zoom svg {
  max-width: none;
}

.c-article-equation__number {
  min-width: 35px;
}

.c-article-orcid {
  margin-block-start: 0;
}
.c-article-orcid__text {
  font-size: 1rem;
}
.c-article-orcid:before {
  display: inline-block;
  width: 24px;
  height: 24px;
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='m0 0h24v24h-24z' fill='%23fff' opacity='0'/%3E%3Cpath d='m12 20c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm-3.01799709-13.09169471c-.16628978-.16109322-.36635416-.24163862-.60019916-.24163862-.21825533 0-.410525.08444276-.57681478.25333081-.16628977.16888806-.24943341.36245684-.24943341.58071218 0 .22345188.08314364.41961891.24943341.58850696.16628978.16888806.35855945.25333082.57681478.25333082.233845 0 .43390938-.08314364.60019916-.24943342.16628978-.16628977.24943342-.36375592.24943342-.59240436 0-.233845-.08314364-.43131115-.24943342-.59240437zm-1.30173065 2.36492841v7.1712107h1.41865256v-7.1712107zm5.97732434.02508949h-2.9924703v7.14612121h2.9106249c.7673039 0 1.4476365-.1483432 2.0410182-.445034s1.0511995-.7152915 1.3734671-1.2558144c.3222677-.540523.4833991-1.1603247.4833991-1.8594238 0-.6854582-.1602789-1.3027023-.4808414-1.8517509-.3205625-.5490485-.7707074-.9753221-1.3504481-1.2788334-.5797408-.30351132-1.2413173-.45526471-1.9847495-.45526471zm-.1432294 6.06678581h-1.4681008v-4.9874504h1.4220628c.7877654 0 1.4143875.2233674 1.8798852.6701087.4654977.4467414.698243 1.05546.698243 1.8261742 0 .7434322-.2310402 1.3444779-.6931277 1.8031551-.4620874.4586773-1.0750688.6880124-1.8389625.6880124z' fill='%23999'/%3E%3C/g%3E%3C/svg%3E");
  margin-left: -3px;
  padding-right: 4px;
  vertical-align: middle;
}

.c-article-editorial-summary__container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
}
.c-article-editorial-summary__container .c-article-editorial-summary__content p:last-child {
  margin-bottom: 0;
}
.c-article-editorial-summary__container .c-article-editorial-summary__content--less {
  max-height: 9.5rem;
  overflow: hidden;
}
.c-article-editorial-summary__container .c-article-editorial-summary__button {
  background-color: white;
  border: none;
  color: #0067c5;
  font-size: 0.875rem;
  margin-bottom: 16px;
}
.c-article-editorial-summary__container .c-article-editorial-summary__button.active, .c-article-editorial-summary__container .c-article-editorial-summary__button:active, .c-article-editorial-summary__container .c-article-editorial-summary__button.hover, .c-article-editorial-summary__container .c-article-editorial-summary__button:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-article-associated-content__container .c-article-associated-content__collection-label {
  font-size: 0.875rem;
  line-height: 1.4;
}
.c-article-associated-content__container .c-article-associated-content__collection-title {
  line-height: 1.3;
}

#uptodate-client {
  display: none !important;
}

/**
 * Copied from https://github.com/springernature/bunsen/blob/master/webapp/src/webapp/assets/scss/partials/_sticky-banner.scss
 * Sticky Banner
 * Persistent (after scrolling past sticky point) horizontal bar
 * at top of page containing call-to-action elements
 *
 */
.c-context-bar {
  position: relative;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
}

.c-context-bar--sticky {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky {
    left: auto;
    right: 0;
  }
}
.c-context-bar--sticky:after {
  background: #fff;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
}
@supports (backdrop-filter: blur(10px)) {
  .c-context-bar--sticky:after {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.75) 50%, #fff);
    backdrop-filter: blur(10px);
  }
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky:after {
    background-color: white;
    background-image: none;
    box-shadow: none;
  }
}

.c-context-bar--no-button.c-context-bar--sticky {
  display: none;
}
@media only screen and (min-width: 540px) {
  .c-context-bar--no-button.c-context-bar--sticky {
    display: block;
  }
}

.c-context-bar--sticky .c-context-bar__container {
  padding: 16px;
}
@media only screen and (min-width: 540px) {
  .c-context-bar--sticky .c-context-bar__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 40px;
  }
}

.c-context-bar__title {
  display: none;
}

.c-context-bar--sticky .c-context-bar__title,
.c-context-bar--sticky .c-context-bar__title p {
  display: none;
}
@media only screen and (min-width: 540px) {
  .c-context-bar--sticky .c-context-bar__title,
  .c-context-bar--sticky .c-context-bar__title p {
    display: block;
    flex: 1;
    padding-right: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.c-context-bar--sticky .c-context-bar__title p {
  margin-bottom: 0;
}

.article__teaser {
  overflow: hidden;
  position: relative;
  font-family: Georgia, Palatino, serif;
}
.article__teaser::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: min(200px, 50%);
  background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}

.c-pdf-preview__embed {
  display: none;
}
@media only screen and (min-width: 540px) {
  .c-pdf-preview__embed {
    display: block;
    margin-bottom: 24px;
  }
}

.c-pdf-preview__embed--height {
  height: 1000px;
}

.c-pdf-preview__info {
  display: block;
}
@media only screen and (min-width: 540px) {
  .c-pdf-preview__info {
    display: none;
  }
}

/* Add to remove white space created by img src */
img[src*="delivery.pbgrd.com/network/logger"] {
  position: absolute;
  height: 0;
  width: 0;
}

/* Temporary until fix is in a released version of elements */
span.u-custom-list-number {
  width: fit-content;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}

.c-download-pdf-icon-large {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: #025e8d;
}

.c-external-link__icon {
  fill: #025e8d;
  vertical-align: middle;
}

/**
 * Article Companion
 * Article styles for browsers that pass CTM
 */
.c-reading-companion {
  clear: both;
  min-height: 389px;
}

.c-reading-companion .u-lazy-ad-wrapper {
  background-color: transparent !important;
  min-height: 250px;
}
.c-reading-companion .u-lazy-ad-wrapper .div-gpt-ad {
  height: 100%;
}

.c-reading-companion__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 16px 16px;
  margin: 0;
}

.c-reading-companion__sticky {
  max-width: 389px;
}
.c-reading-companion__sticky--stuck {
  margin: 0 0 16px;
  position: fixed;
  top: 50px;
}

.c-reading-companion__scroll-pane {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 0 0;
  min-height: 200px;
}

.c-reading-companion__tabs {
  font-size: 1rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 8px;
  padding: 0;
}
.c-reading-companion__tabs > li {
  flex-grow: 1;
}

.c-reading-companion__tab {
  color: #0067c5;
  border: solid rgb(212.5, 212.5, 212.5);
  border-width: 1px 1px 1px 0;
  background-color: #eeeeee;
  padding: 8px 8px 8px 15px;
  text-align: left;
  font-size: 1rem;
  width: 100%;
}
.c-reading-companion__tab:focus {
  text-decoration: none;
  outline-offset: -4px;
}

.c-reading-companion__tabs li:first-child .c-reading-companion__tab {
  border-left-width: 1px;
}

.c-reading-companion__tab--active {
  color: #222222;
  background-color: white;
  border-bottom: 1px solid white;
  font-weight: bold;
}

.c-reading-companion--highlighted {
  -webkit-animation: highlight 2s ease;
  animation: highlight 2s ease;
}

@-webkit-keyframes highlight {
  0% {
    background-color: #ff9;
  }
  60% {
    background-color: #ff9;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes highlight {
  0% {
    background-color: #ff9;
  }
  60% {
    background-color: #ff9;
  }
  100% {
    background-color: #fff;
  }
}
.c-reading-companion__sections-list,
.c-reading-companion__figures-list,
.c-reading-companion__references-list {
  list-style: none;
  padding: 0;
  min-height: 389px;
}

.c-reading-companion__references-list--numeric {
  list-style: decimal inside;
}

.c-reading-companion__sections-list {
  margin: 0 0 8px;
  min-height: 50px;
}

.c-reading-companion__section-item {
  font-size: 1rem;
  padding: 0;
}
.c-reading-companion__section-item a {
  display: block;
  padding: 8px 0 8px 16px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.c-reading-companion__section-item--active a {
  white-space: normal;
  border-left: 4px solid rgb(212.5, 212.5, 212.5);
  padding-left: 12px;
  color: #222222;
}
.c-reading-companion__section-item .c-article-section__title-number {
  display: none;
}

.c-reading-companion__figure-item {
  padding: 16px 8px 16px 0;
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  font-size: 1rem;
}
.c-reading-companion__figure-item:first-child {
  border-top: none;
  padding-top: 8px;
}

.c-reading-companion__reference-item {
  padding: 8px 8px 8px 16px;
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  font-size: 1rem;
}
.c-reading-companion__reference-item:first-child {
  border-top: none;
}
.c-reading-companion__reference-item a {
  word-break: break-word;
}

.c-reading-companion__reference-citation {
  display: inline;
}

.c-reading-companion__reference-links {
  list-style: none;
  text-align: right;
  margin: 8px 0 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.813rem;
}
.c-reading-companion__reference-links > a {
  display: inline-block;
  padding-left: 8px;
}
.c-reading-companion__reference-links > a:first-child {
  display: inline-block;
  padding-left: 0;
}

.c-reading-companion__figure-title {
  display: block;
  margin: 0 0 8px;
}

.c-reading-companion__figure-links {
  margin: 8px 0 0;
  display: flex;
  justify-content: space-between;
}

.c-reading-companion__figure-links > a {
  display: flex;
  align-items: center;
}

.c-reading-companion__figure-item picture:last-child {
  margin-bottom: 16px;
}

.c-reading-companion__figure-full-link svg {
  height: 0.8em;
  margin-left: 2px;
}

.c-reading-companion__panel {
  display: none;
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.c-reading-companion__panel--active {
  display: block;
}

.c-reading-companion__return {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
.c-reading-companion__return:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0 4px;
}

.c-behind-the-paper {
  display: none;
}

/**
 * Article Tables and Figures
 * Article styles for browsers that pass CTM
 */
.c-article-section__figure {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  padding: 20px 10px;
  max-width: 100%;
  margin-bottom: 24px;
  clear: both;
}

.c-article-section__figure--1-border {
  border-width: 1px;
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
}

.c-article-section__figure--1-border-image {
  float: left;
  max-width: 100%;
}

.c-article-section__figure--no-border {
  border: none;
  padding: 20px 0;
}

.c-article-section__figure-caption {
  margin-bottom: 8px;
  display: block;
  word-break: break-word;
}

.c-article-section__figure-1-border-caption {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  color: rgb(97.75, 97.75, 97.75);
  font-size: 0.813rem;
  margin: 0;
  padding: 8px 0;
  max-width: 100%;
}

.c-article-section__figure-credit {
  font-size: 0.813rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 16px;
}

.c-article-section__figure-credit-right {
  text-align: right;
}

.c-article-section__figure-content {
  margin-bottom: 16px;
}

.c-article-section__figure-item {
  max-width: 100%;
}

.c-article-section__figure-link {
  max-width: 100%;
  display: block;
  margin: 0 0 16px;
  padding: 0;
}

.c-article-section__figure .video {
  margin: 0 0 16px;
}

.c-article-section__figure-item img {
  display: block;
  margin: 0 auto;
}

.c-article-section__figure-description {
  font-size: 1rem;
}

.c-article-section__figure-description > * {
  margin-bottom: 0;
}

.c-article-figure-content {
  clear: both;
}

.c-article-figure-content-image {
  margin-bottom: 16px;
  text-align: center;
}

.c-article-figure-content-image:before,
.c-article-figure-content-image:after {
  content: " ";
  display: table;
}

.c-article-figure-content-image:after {
  clear: both;
}

.c-article-figure-content-image-inline {
  display: inline-block;
}

.c-article-figure-description {
  font-family: Georgia, Palatino, serif;
  margin: 0 0 24px 0;
}

.c-article-figure-description > * {
  margin-bottom: 0;
}

.c-article-figure-description > * + * {
  margin-top: 24px;
}

.c-article-figure-button-container,
.c-article-metrics__button-container {
  text-align: right;
}

.c-article-table {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  clear: both;
  padding: 20px 10px;
  margin-bottom: 24px;
}

.c-article-table-image {
  text-align: center;
}

.c-article-table__figcaption,
.c-article-satellite-title {
  line-height: 1.4;
  margin-bottom: 16px;
  word-break: break-word;
}

.c-article-satellite-subtitle {
  font-size: 1.125rem;
  font-family: Georgia, Palatino, serif;
  margin-bottom: 16px;
}

.c-article-table__inline-caption {
  font-weight: normal;
  font-size: 1.05rem;
  margin-bottom: 16px;
}

.c-article-table-container {
  font-family: Georgia, Palatino, serif;
  margin-bottom: 24px;
}

.c-article-table-border {
  border: 1px solid rgb(212.5, 212.5, 212.5);
}

.c-article-table-container table {
  border: 2px solid rgb(212.5, 212.5, 212.5);
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 0;
  width: 100%;
}

.c-article-table-container th,
.c-article-table-container td {
  padding: 6px;
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  line-height: 1.15;
}

.c-article-table-container th p,
.c-article-table-container td p {
  word-break: normal;
}

.c-article-table-head {
  border-bottom: 5px solid rgb(148.75, 148.75, 148.75);
}

.c-article-table-footer {
  padding: 10px;
}

.c-article-table-footer ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-table-scroll-wrapper {
  position: relative;
}

.c-table-scroll-wrapper__content {
  overflow-x: auto;
  min-width: 100%;
}

.c-table-scroll-wrapper__fade::after {
  content: "";
  width: 30px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), #fff);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity;
}

.c-table-scroll-wrapper__fade--transparent::after {
  opacity: 0;
}

.c-aod {
  background: #dae5ea;
  padding: 20px;
  margin-bottom: 24px;
  font-size: 1rem;
}

.c-aod__prompt {
  display: block;
  margin-bottom: 8px;
}

.c-aod__invalid {
  display: block;
  margin-top: 8px;
}

.c-aod input {
  padding: 5px;
  border: 1px solid #173962;
}

.c-aod input[type=submit] {
  background: #173962;
  color: white;
  padding: 5px 10px;
}

.c-access-options {
  margin: 16px 0 40px;
}
.c-access-options__heading {
  font-weight: bold;
}
.c-access-options__list {
  margin: 0;
  padding: 0;
  list-style: disc inside;
}
.c-access-options__list > li {
  font-size: 1rem;
  line-height: 1.4;
}

.c-cod {
  font-size: 1rem;
  display: block;
  width: 100%;
}

.c-cod__form {
  background: #ebf0f3;
}

.c-cod__prompt {
  margin: 0 0 24px;
  font-size: 1.125rem;
  line-height: 1.3;
}

.c-cod__label {
  display: block;
  margin: 0 0 4px;
}

.c-cod__row {
  display: flex;
  margin: 0 0 16px;
}
.c-cod__row:last-child {
  margin: 0;
}

.c-cod__input {
  padding: 13px;
  margin: 0;
  border: 1px solid rgb(212.5, 212.5, 212.5);
  border-radius: 2px;
  flex-basis: 75%;
  flex-shrink: 0;
}

.c-cod__input--submit {
  margin-left: 8px;
  flex-shrink: 1;
  background-color: #173962;
  color: white;
  border: 1px solid #173962;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.c-cod__input--submit-single {
  margin: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.c-cod__input--submit:hover,
.c-cod__input--submit:focus {
  color: #173962;
  background-color: white;
}

.c-pdf-download__link .u-icon {
  padding-top: 2px;
}

/**
 * Article Save data - article styles for all browsers with lite mode on
 * styles below override any from shared-article/50-components with font-family: $context--font-family-serif;
 */
.save-data h1,
.save-data h2,
.save-data h3,
.save-data .serif,
.save-data .u-serif,
.save-data .c-site-messages--nature-briefing-email-variant .serif,
.save-data .c-site-messages--nature-briefing-email-variant.serif,
.save-data .c-article-main-column,
.save-data .c-article-author-institutional-author__sub-division,
.save-data .c-article-fullwidth-content,
.save-data .c-article-satellite-article-link,
.save-data .c-code-block__heading,
.save-data .c-blockquote__body,
.save-data .c-article-equation__number,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-satellite-article-link,
.save-data .c-article-figure-description,
.save-data .c-article-satellite-subtitle,
.save-data .c-article-table-container,
.save-data .c-reading-companion__reference-citation,
.save-data .c-reading-companion__figure-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

/* NEW STYLING FOR PDF LINKS
Remove parent selector when all pdf links are adapted consistently across SN, Magazine, BMC and SL
*/
.c-pdf-download {
  display: flex;
  margin-bottom: 16px;
  max-height: 48px;
}
@media only screen and (min-width: 540px) {
  .c-pdf-download {
    max-height: none;
  }
}
@media only screen and (min-width: 1024px) {
  .c-pdf-download {
    max-height: 48px;
  }
}

.c-pdf-download__link {
  display: flex;
  flex: 1;
  padding: 13px 24px;
}
.c-pdf-download__link:hover {
  text-decoration: none;
}

.c-pdf-download__text {
  padding-right: 4px;
}
@media only screen and (max-width: 539px) {
  .c-pdf-download__text {
    text-transform: capitalize;
  }
}
@media only screen and (min-width: 540px) {
  .c-pdf-download__text {
    padding-right: 8px;
  }
}

.c-context-bar--sticky .c-pdf-download {
  margin-bottom: 0;
  display: block;
  white-space: nowrap;
}

@media only screen and (max-width: 539px) {
  .c-pdf-download .u-sticky-visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}

.c-pdf-container {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 539px) {
  .c-pdf-container .c-pdf-download {
    display: flex;
    flex-basis: 100%;
  }
}

.c-pdf-container .c-pdf-download + .c-pdf-download {
  margin-left: 16px;
}

.c-article-extras .c-pdf-container {
  width: 100%;
  flex-wrap: wrap;
}
.c-article-extras .c-pdf-container .c-pdf-download {
  width: 100%;
}
.c-article-extras .c-pdf-container .c-pdf-download + .c-pdf-download {
  margin-left: 0;
}

@media only screen and (min-width: 540px) {
  .c-context-bar--sticky .c-pdf-download__link {
    flex: 1 1 183px;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .c-context-bar--sticky .c-pdf-download__link {
    padding: 16px;
  }
}

.article-page--commercial {
  /* PDF link show/hide in main body within c-pdf-button__container depending on viewport */
}
.article-page--commercial .c-pdf-container {
  display: block;
}
.article-page--commercial .c-article-main-column .c-pdf-button__container .c-pdf-download {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .article-page--commercial .c-article-main-column .c-pdf-button__container .c-pdf-download {
    display: block;
  }
}

/* Inline variant styles */
.c-article-recommendations {
  padding: 24px;
  background-color: white;
  margin: 0 0 48px;
}
.c-article-recommendations-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
}

.c-book-body .c-article-recommendations {
  margin: 0 0 64px;
}

.c-book-body .c-article-recommendations-list,
.c-article-body .c-article-recommendations-list {
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 100%;
  margin: 0;
  padding: 16px 0 0;
}
.c-book-body .c-article-recommendations-list__item,
.c-article-body .c-article-recommendations-list__item {
  flex: 1;
}
@media only screen and (max-width: 539px) {
  .c-book-body .c-article-recommendations-list,
  .c-article-body .c-article-recommendations-list {
    flex-direction: column;
  }
}

.c-article-recommendations-list:before,
.c-article-recommendations-list:after {
  align-self: stretch;
  content: "";
  border-left: 1px solid blue;
}

@media only screen and (max-width: 539px) {
  .c-article-recommendations-list:before,
  .c-article-recommendations-list:after {
    border-bottom: 1px solid blue;
    border-left: none;
  }
}

.c-article-recommendations-list__item {
  display: flex;
}

.c-article-recommendations-list .c-article-recommendations-list__item:first-child {
  order: -1;
}

.c-article-recommendations-list .c-article-recommendations-list__item:last-child {
  order: 1;
}

.c-article-recommendations-card {
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: hidden;
  position: relative;
}
.c-article-recommendations-card__img {
  margin-bottom: 8px;
}

@media only screen and (max-width: 539px) {
  .c-article-recommendations-card__img {
    display: none;
  }
}

.c-article-recommendations-card__heading {
  max-height: 5.6em;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 16px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-height: 1.4;
}
@media only screen and (max-width: 539px) {
  .c-article-recommendations-card__heading {
    margin: 0 0 8px;
  }
}

.c-article-recommendations-card__link {
  color: blue;
}
.c-article-recommendations-card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.c-article-recommendations-card__link:hover, .c-article-recommendations-card__link:visited {
  color: blue;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.08em;
}
.c-article-recommendations-card__link .MathJax_Display {
  display: inline !important;
}

.c-article-recommendations-card__main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
@media only screen and (max-width: 539px) {
  .c-article-recommendations-card__main {
    flex-direction: column-reverse;
  }
}

.c-recommendations-column-switch .c-meta {
  margin-top: auto;
}

.c-meta .c-meta__item:first-child,
.c-article-recommendations-card__meta-type {
  font-weight: 700;
}

.c-article-meta-recommendations {
  margin-top: auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: inherit;
}
.c-article-meta-recommendations__item-type {
  font-weight: 700;
  border-right: 1px solid #d9d9d9;
  padding-right: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 539px) {
  .c-article-meta-recommendations__access-type {
    border-right: 1px solid #d9d9d9;
    padding-right: 8px;
    margin-right: 8px;
  }
}

.c-article-recommendations-list:has(.c-article-meta-recommendations__access-type) .c-article-meta-recommendations:not(:has(> .c-article-meta-recommendations__access-type)) {
  padding: 0 0 1.5rem;
}
@media only screen and (max-width: 539px) {
  .c-article-recommendations-list:has(.c-article-meta-recommendations__access-type) .c-article-meta-recommendations:not(:has(> .c-article-meta-recommendations__access-type)) {
    padding: 0;
  }
}

.c-article-meta-recommendations__access-type + .c-article-meta-recommendations__date {
  display: block;
}
@media only screen and (max-width: 539px) {
  .c-article-meta-recommendations__access-type + .c-article-meta-recommendations__date {
    display: inline;
  }
}

.c-article-body .c-article-recommendations-card__authors {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0 0 8px;
}
@media only screen and (max-width: 539px) {
  .c-article-body .c-article-recommendations-card__authors {
    display: block;
    margin: 0;
  }
}

/**
 * Article History
 * Article styles for the article history section found in "about the article/chapter"
 */
.c-article-history {
  border-left: 2px dotted #999;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 540px) {
  .c-article-history {
    margin-left: 96px;
  }
}

.c-article-body .c-article-history {
  margin-top: 24px;
}

.c-article-history-item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  padding: 0 0 24px 14px;
  margin-left: -2px;
  position: relative;
}
@media only screen and (min-width: 540px) {
  .c-article-history-item {
    padding-left: 26px;
  }
}
.c-article-history-item:before {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #999;
  left: -5px;
  top: 7px;
  padding: 0;
  content: "";
  position: absolute;
}
.c-article-history-item:first-child {
  background-image: linear-gradient(to bottom, #fff 0, #fff 12px, transparent 12px, transparent);
}
.c-article-history-item:last-child {
  background-image: linear-gradient(to bottom, transparent 0, transparent 12px, #fff 12px, #fff);
}
.c-article-history-item--current:before {
  background: #999;
}
.c-article-history-item__marker {
  margin: 0;
}
@media only screen and (min-width: 540px) {
  .c-article-history-item__marker {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: absolute;
    left: -100px;
    top: 1px;
    text-align: right;
    width: 96px;
    padding-right: 8px;
  }
}
.c-article-history-item__title {
  margin-bottom: 12px;
  display: inline-block;
}
.c-article-history-item__authors {
  margin: 0;
  padding: 0;
}
.c-article-history-item__authors li {
  display: inline;
}
.c-article-history-item__authors li:after {
  content: ",";
}
.c-article-history-item__authors li:last-child:after {
  content: "";
}
.c-article-history-item .c-article-history-item__dates {
  line-height: 1.8;
  margin: 0;
}
.c-article-history-item .c-article-history-item__dates dd,
.c-article-history-item .c-article-history-item__dates dt {
  display: inline;
  margin: 0;
  padding: 0 0.25em 0 0;
}
.c-article-history-item .c-article-history-item__dates dt {
  font-weight: 700;
}
.c-article-history-item__doi {
  margin: 16px 0;
}
.c-article-history-item__changelog h3 {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.7;
  margin-bottom: 0;
}
.c-article-history-item__changelog p:first-of-type {
  margin-top: 0;
}

.c-article-history-item a {
  font-size: 1.125rem;
  line-height: 1.5;
}

.c-author-popup__author-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
}
.c-author-popup__subheading {
  font-weight: 700;
  margin: 0;
}
.c-author-popup__footer-heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.c-author-popup__author-list {
  list-style: none;
  font-size: 1rem;
  padding: 0;
  margin-top: 0;
  clear: both;
  margin-bottom: 16px;
}
.c-author-popup__author-list > li {
  margin-bottom: 8px;
}
.c-author-popup__link {
  font-weight: 700;
  vertical-align: baseline;
  color: #0067c5;
  text-decoration: none;
}

.c-author-popup .c-article-button {
  color: white;
  background: #173962;
  border: 1px solid transparent;
  border-radius: 2px;
  text-decoration: none;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;
}
.c-author-popup .c-article-button:visited {
  color: white;
}
.c-author-popup .c-article-button:hover {
  color: white;
  background: #0067c5;
  text-decoration: none;
}

.c-author-popup .c-article-authors-search__list {
  clear: both;
}
@media only screen and (min-width: 768px) {
  .c-author-popup .c-article-authors-search__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (min-width: 768px) {
  .c-author-popup .c-article-authors-search__list-item--left {
    flex-basis: 40%;
  }
}
.c-author-popup .c-article-authors-search__list-item--right {
  margin-top: 16px;
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .c-author-popup .c-article-authors-search__list-item--right {
    text-align: right;
    flex: 1;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .c-author-popup .c-article-identifiers {
    justify-content: flex-end;
  }
}

.c-author-popup .c-article-authors-search__item,
.c-author-popup .c-article-identifiers {
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .c-author-popup .c-article-identifiers {
    justify-content: flex-end;
  }
}

/**
 * Article Metrics
 * Article metrics styles
 */
/**
 * Article Metrics
 * Article metrics styles
 */
.c-article-metrics__heading {
  line-height: 1.4;
  margin-bottom: 16px;
}
.c-article-metrics__heading a {
  color: inherit;
}

.c-article-metrics__body {
  display: flex;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-article-metrics__section--left {
  font-size: 1rem;
  flex-shrink: 1;
  flex-basis: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__section--left {
    flex-basis: 60%;
  }
}
.c-article-metrics__section--left > li:last-child {
  margin-right: 0;
}
.c-article-metrics__section--left p {
  line-height: 24px;
}

.c-article-metrics__explanation {
  color: #222222;
  font-size: 0.875rem;
  line-height: 24px;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__explanation {
    padding-left: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-article-metrics__explanation {
    padding-left: 80px;
  }
}

.c-article-metrics__section--right {
  flex-grow: 0;
  flex-shrink: 1;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__section--right {
    flex-basis: 40%;
  }
}

.c-article-metrics__section-heading {
  line-height: 1.4;
  margin-bottom: 16px;
  font-weight: bold;
  border-bottom: 2px solid #222222;
}

.c-article-metrics__numbers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.c-article-metrics__numbers li {
  padding-top: 8px;
  padding-right: 16px;
  flex-grow: 1;
}
@media only screen and (min-width: 1024px) {
  .c-article-metrics__numbers li {
    padding-top: 0;
  }
}

.c-article-metrics__access-citation {
  display: flex;
  flex-flow: column;
  color: #222222;
}
.c-article-metrics__access-citation:nth-child(1) {
  order: 2;
}
.c-article-metrics__access-citation:nth-child(2) {
  order: 1;
}
.c-article-metrics__access-citation dt {
  font-size: 1.5rem;
  font-weight: 500;
}
.c-article-metrics__access-citation dd {
  font-size: 1.125rem;
  margin: 0;
}

.c-article-metrics__image img {
  max-height: 128px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  min-width: 80px;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__image img {
    margin: 0;
  }
}

.c-article-metrics__altmetric-donut {
  padding-bottom: 24px;
}
@media only screen and (min-width: 540px) {
  .c-article-metrics__altmetric-donut {
    display: flex;
    align-items: center;
  }
}

.c-article-metrics__legend {
  margin-top: 24px;
}
@media only screen and (min-width: 540px) {
  .c-article-metrics__legend {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__legend {
    flex-basis: 75%;
    justify-content: right;
  }
}
.c-article-metrics__legend ul {
  display: flex;
  flex-wrap: wrap;
}
.c-article-metrics__legend li {
  flex-basis: 100%;
  padding: 8px 0 0 16px;
}
@media only screen and (min-width: 768px) {
  .c-article-metrics__legend li {
    flex: 0 1 130px;
  }
}
@supports (display: grid) and (grid-auto-rows: auto) {
  .c-article-metrics__legend ul {
    display: grid;
    grid-column-gap: 8px;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  @media only screen and (min-width: 540px) {
    .c-article-metrics__legend ul {
      padding-left: 16px;
      grid-template-columns: repeat(2, 50%);
    }
  }
  @media only screen and (min-width: 1024px) {
    .c-article-metrics__legend ul {
      grid-template-columns: repeat(3, 33%);
    }
  }
  .c-article-metrics__legend ul li {
    font-size: 0.875rem;
  }
}

.c-article-metrics__posts .c-card__title {
  font-size: 1.05rem;
}
.c-article-metrics__posts .c-card__title + span {
  color: rgb(110.5, 110.5, 110.5);
  font-size: 1rem;
}
.c-article-metrics__posts .c-card__title a {
  color: inherit;
}

.c-article-metrics__posts li {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.c-article-metrics__posts li:first-child {
  padding-top: 0;
}

.c-article-metrics__altmetric-key {
  border-radius: 50%;
  display: block;
  float: left;
  height: 16px;
  margin-right: 8px;
  width: 16px;
}
.c-article-metrics__altmetric-key > span {
  font-weight: bold;
}
.c-article-metrics__altmetric-key--twitter {
  background-color: #2f90b9;
}
.c-article-metrics__altmetric-key--blogs {
  background-color: #ffc200;
}
.c-article-metrics__altmetric-key--facebook {
  background-color: #071d70;
}
.c-article-metrics__altmetric-key--googleplus {
  background-color: #912470;
}
.c-article-metrics__altmetric-key--news {
  background-color: #b60000;
}
.c-article-metrics__altmetric-key--linkedin {
  background-color: #00bfff;
}
.c-article-metrics__altmetric-key--reddit {
  background-color: #b9ddeb;
}
.c-article-metrics__altmetric-key--f1000 {
  background-color: #cb2d2d;
}
.c-article-metrics__altmetric-key--pinterest {
  background-color: #c30;
}
.c-article-metrics__altmetric-key--qna {
  background-color: #efefef;
}
.c-article-metrics__altmetric-key--video {
  background-color: #98c973;
}
.c-article-metrics__altmetric-key--wikipedia {
  background-color: #c7c7c7;
}
.c-article-metrics__altmetric-key--reviews {
  background-color: #d45bb0;
}
.c-article-metrics__altmetric-key--citeulike {
  background-color: #bcd2ef;
}
.c-article-metrics__altmetric-key--mendeley {
  background-color: #a60000;
}
.c-article-metrics__altmetric-key--connotea {
  background-color: #ff4040;
}
.c-article-metrics__altmetric-key + span {
  display: block;
  margin-left: 24px;
}

/**
 * Card
 * Base component styles
 */
.c-card {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  font-size: 14px;
  min-width: 0;
  padding: 0;
  position: relative;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.c-card--no-shape {
  border: none;
  box-shadow: none;
  background: none;
}

.c-card__image {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
}
@supports (aspect-ratio: 1/1) {
  .c-card__image {
    padding-bottom: 0;
    aspect-ratio: var(--card--image-aspect-ratio, 16/9);
  }
}
.c-card__image img {
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}
@supports (object-fit: cover) {
  .c-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-card__body {
  padding: 16px;
  flex: 1 1 auto;
}

.c-card--no-shape .c-card__body {
  padding: 0;
}

.c-card--no-shape .c-card__body:not(:first-child) {
  padding-top: 16px;
}

.c-card__title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 8px;
  letter-spacing: normal;
}

[lang=de] .c-card__title {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.c-card__summary {
  line-height: 1.4;
}
.c-card__summary > p {
  margin-bottom: 5px;
}
.c-card__summary > p:last-child {
  margin-bottom: 0;
}
.c-card__summary a {
  text-decoration: underline;
}

.c-card__link:not(.c-card__link--no-block-link)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.c-card--flush .c-card__body {
  padding: 0;
}

.c-card--major {
  font-size: 1rem;
}
.c-card--dark {
  background-color: #000;
  color: #fff;
  border-width: 0;
}
.c-card--dark .c-card__title {
  color: white;
}
.c-card--dark .c-card__link {
  color: inherit;
}
.c-card--dark .c-card__summary a {
  color: inherit;
}

.js .c-popup {
  position: absolute;
  font-family: Georgia, Palatino, serif;
  z-index: 100;
  padding: 16px;
  border: 1px solid #222222;
  box-shadow: 0 0 5px 0 rgba(161.5, 161.5, 161.5, 0.1);
  width: auto;
  border-radius: 2px;
  background-color: #fff;
}
.js .c-popup__close {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.29679575 12.2772478c-.39658757.3965876-.39438847 1.0328109-.00062148 1.4265779.39651227.3965123 1.03246768.3934888 1.42657791-.0006214l4.27724782-4.27724787 4.2772478 4.27724787c.3965876.3965875 1.0328109.3943884 1.4265779.0006214.3965123-.3965122.3934888-1.0324677-.0006214-1.4265779l-4.27724787-4.2772478 4.27724787-4.27724782c.3965875-.39658757.3943884-1.03281091.0006214-1.42657791-.3965122-.39651226-1.0324677-.39348875-1.4265779.00062148l-4.2772478 4.27724782-4.27724782-4.27724782c-.39658757-.39658757-1.03281091-.39438847-1.42657791-.00062148-.39651226.39651227-.39348875 1.03246768.00062148 1.42657791l4.27724782 4.27724782z' fill='%23666' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
  border: none;
  padding-right: 16px;
}
.js .c-popup__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.js .c-popup__arrow {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top: 1px solid #222222;
  border-left: 1px solid #222222;
}
.js .c-popup__arrow--above {
  bottom: -11px;
  transform: rotate(225deg);
}
.js .c-popup__arrow--below {
  top: -11px;
  transform: rotate(45deg);
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 320px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-serif {
  font-family: Georgia, Palatino, serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

/* Handle the js events for hide and show elements. This has to be removed once js code for the
 global-article is refactored on the frontend-global-toolkit */
/*
  Please note that this content has been added temporarily to fix some issues with some
  non working js for the global-article.
  This is a fix, DO NOT USE IT for new brand code. More information ask some FED in @orion-team
 */
/* =========================================================================
   $HIDING
   various ways of hiding stuff
   ========================================================================= */
/* shunter-proxy hiding class at ui breakpoints */
/* =========================================================================
   $HIDING
   various ways of hiding stuff
   ========================================================================= */
/* hide from both screenreaders and browsers */
.hide,
.grade-a-hide,
.js .js-hide {
  display: none;
  visibility: hidden;
}

/* show to both screenreaders and browsers */
.show,
.js .js-show {
  display: block;
  visibility: visible;
}

.show-inline,
.js .js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.visually-hidden,
.js .js-visually-hidden {
  width: 1px;
  height: 1px;
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.js .js-author-etal {
  display: none;
  visibility: hidden;
}

.js .js-authors-expanded .js-author-etal {
  display: inline;
  visibility: visible;
}

/* less than or equal to 1200px */
@media only screen and (max-width: 75em) {
  .mq1200-hide,
  .js .js-mq1200-hide {
    display: none;
    visibility: hidden;
  }
  .mq1200-show,
  .js .js-mq1200-show {
    display: block;
    visibility: visible;
  }
  .mq1200-show-inline,
  .js .js-mq1200-show-inline {
    display: inline;
    visibility: visible;
  }
  .mq1200-visually-show {
    clip: auto;
    height: auto;
    position: static !important;
    width: auto;
  }
}
/* less than or equal to 875px */
@media only screen and (max-width: 54.688em) {
  .mq875-hide,
  .js .js-mq875-hide {
    display: none;
    visibility: hidden;
  }
  .mq875-show,
  .js .js-mq875-show {
    display: block;
    visibility: visible;
  }
  .mq875-show-inline,
  .js .js-mq875-show-inline {
    display: inline;
    visibility: visible;
  }
  .mq875-visually-show {
    clip: auto;
    height: auto;
    position: static !important;
    width: auto;
  }
}
/* less than or equal to 640px */
@media only screen and (max-width: 40em) {
  .mq640-hide,
  .js .js-mq640-hide {
    display: none;
    visibility: hidden;
  }
  .mq640-show,
  .js .js-mq640-show {
    display: block;
    visibility: visible;
  }
  .mq640-show-inline,
  .js .js-mq640-show-inline {
    display: inline;
    visibility: visible;
  }
  .mq640-visually-show {
    clip: auto;
    height: auto;
    position: static !important;
    width: auto;
  }
}
/* less than or equal to 480px */
@media only screen and (max-width: 30em) {
  .mq480-hide,
  .js .js-mq480-hide {
    display: none;
    visibility: hidden;
  }
  .mq480-show,
  .js .js-mq480-show {
    display: block;
    visibility: visible;
  }
  .mq480-show-inline,
  html.js .js-mq480-show-inline {
    display: inline !important;
    visibility: visible !important;
  }
  .mq480-visually-show {
    clip: auto;
    height: auto;
    position: static !important;
    width: auto;
  }
  .js .js-smaller-author-etal {
    display: none;
    visibility: hidden;
  }
  .js .js-authors-expanded .js-smaller-author-etal {
    display: inline;
    visibility: visible;
  }
}
.c-article-section__figure-description {
  font-family: Georgia, Palatino, serif;
}

.u-reset-margin a:hover {
  text-decoration: inherit;
}

/* TEMPORARY STYLES FOR MANAGING ASSOCIATED CONTENT.

Parallely, it'd be necessary to refactor the whole html block to make it semantically
a list of items
*/
.c-article-associated-content__container a {
  text-decoration: underline;
}
.c-article-associated-content__container a:hover {
  text-decoration-thickness: 0.3875rem;
  text-underline-offset: 0.08em;
}
.c-article-associated-content__container .c-article-associated-content__title {
  display: none;
}
.c-article-associated-content__container .c-article-associated-content__collection.section .c-article-associated-content__collection-label,
.c-article-associated-content__container .c-article-associated-content__collection.collection .c-article-associated-content__collection-label {
  display: block;
}
.c-article-associated-content__container .c-article-associated-content__collection.section,
.c-article-associated-content__container .c-article-associated-content__collection.collection {
  margin-bottom: 5px;
}
.c-article-associated-content__container .c-article-associated-content__collection.section ~ .c-article-associated-content__collection.collection {
  margin-top: 28px;
}
.c-article-associated-content__container .c-article-associated-content__collection.section ~ .c-article-associated-content__collection.section .c-article-associated-content__collection-label,
.c-article-associated-content__container .c-article-associated-content__collection.collection ~ .c-article-associated-content__collection.collection .c-article-associated-content__collection-label {
  display: none;
}
.c-article-associated-content__container .c-article-associated-content__collection:first-child {
  margin-top: 0;
}
.c-article-associated-content__container .c-article-associated-content__collection:last-child {
  margin-bottom: 2.4rem;
}
.c-article-associated-content__container .c-article-associated-content__collection-label {
  color: #1b3051;
}
.c-article-associated-content__container .c-article-associated-content__collection-title {
  font-size: 1.063rem;
  font-weight: normal;
}

.cms-editor-note {
  margin-top: 16px;
}

.c-alert {
  margin-top: 16px;
}

/* temporary fix for layout in metrics page */
@media only screen and (max-width: 75em) {
  .mq1200-padded {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.c-banner {
  margin-top: -13px;
}

.c-article-figshare-container {
  min-height: 1px;
}

.c-article-figshare-container:not(:empty) {
  cursor: default;
  margin-bottom: 16px;
  margin-top: 8px;
}

.webfonts-loaded .c-article__sub-heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.cms-editor-note + .c-status-message {
  margin-top: 8px;
}