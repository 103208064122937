/**
 * Article Layout
 * Article styles for general article column widths
 */

.c-article-main-column {
	font-family: $article--font-family;
	margin-right: 8.6%;
	width: 60.2%;

	@include media-query('lg', 'max') {
		margin-right: 0;
		width: 100%;
	}
}

.c-article-extras {
	float: left;
	font-family: $context--font-family-sans;
	width: 31.2%;

	@include media-query('lg', 'max') {
		display: none;
	}
}
