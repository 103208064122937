/**
 * Article History
 * Article styles for the article history section found in "about the article/chapter"
 */

.c-article-history {
	border-left: 2px dotted #999;
	margin: 0;
	padding: 0;

	@include media-query('sm') {
		margin-left: 96px;
	}
}

.c-article-body .c-article-history {
	margin-top: 24px;
}

.c-article-history-item {
	font-family: $context--font-family-sans;
	font-size: map-get($article--font-sizes, 'font-size-2');
	padding: 0 0 24px 14px;
	margin-left: -2px;

	position: relative;

	@include media-query('sm') {
		padding-left: 26px;
	}

	&:before {
		background: #fff;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 2px solid #999;
		left: -5px;
		top: 7px;
		padding: 0;
		content: "";
		position: absolute;
	}

	&:first-child {
		background-image: linear-gradient(to bottom, #fff 0, #fff 12px, transparent 12px, transparent);
	}

	&:last-child {
		background-image: linear-gradient(to bottom, transparent 0, transparent 12px, #fff 12px, #fff);
	}

	&--current {
		&:before {
			background: #999;
		}
	}

	&__marker {
		margin: 0;

		@include media-query('sm') {
			font-family: $context--font-family-sans;
			position: absolute;
			left: -100px;
			top: 1px;
			text-align: right;
			width: 96px;
			padding-right: 8px;
		}
	}

	&__title {
		margin-bottom: 12px;
		display: inline-block;
	 }

	&__authors {
		margin: 0;
		padding: 0;

		// author comma separator
		li {
			display: inline;

			&:after {
				content: ',';
			}

			&:last-child:after {
				content: '';
			}

		}
	}

	& &__dates {
		line-height: 1.8;
		margin: 0;

		dd,
		dt {
			display: inline;
			margin: 0;
			padding: 0 .25em 0 0;
		}

		dt {
			font-weight: 700;
		}
	}

	&__doi {
		margin: $article--vertical-spacing-medium 0;
	}


	&__changelog {
		h3 {
			font-size: map-get($article--font-sizes, 'font-size-2');
			font-family: $context--font-family-sans;
			font-weight: 700;
			line-height: 1.7;
			margin-bottom: 0;
		}

		p {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}
}

.c-article-history-item a {
	font-size: map-get($article--font-sizes, 'font-size-5');
	line-height: 1.5;
}
