/*
  Please note that this content has been added temporarily to fix some issues with some
  non working js for the global-article.
  This is a fix, DO NOT USE IT for new brand code. More information ask some FED in @orion-team
 */

/* =========================================================================
   $HIDING
   various ways of hiding stuff
   ========================================================================= */

/* shunter-proxy hiding class at ui breakpoints */


/* =========================================================================
   $HIDING
   various ways of hiding stuff
   ========================================================================= */

/* hide from both screenreaders and browsers */

.hide,
.grade-a-hide,
.js .js-hide {
	display: none;
	visibility: hidden;
}

/* show to both screenreaders and browsers */

.show,
.js .js-show {
	display: block;
	visibility: visible;
}

.show-inline,
.js .js-show-inline {
	display: inline;
	visibility: visible;
}

/* hide only visually, but have it available for screenreaders */

.visually-hidden,
.js .js-visually-hidden {
	width: 1px;
	height: 1px;
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
}

.js .js-author-etal {
	display: none;
	visibility: hidden;
}

.js .js-authors-expanded .js-author-etal {
	display: inline;
	visibility: visible;
}

/* less than or equal to 1200px */

@media only screen and (max-width: 75em) {
	.mq1200-hide,
	.js .js-mq1200-hide {
		display: none;
		visibility: hidden;
	}

	.mq1200-show,
	.js .js-mq1200-show {
		display: block;
		visibility: visible;
	}

	.mq1200-show-inline,
	.js .js-mq1200-show-inline {
		display: inline;
		visibility: visible;
	}

	.mq1200-visually-show {
		clip: auto;
		height: auto;
		position: static !important;
		width: auto;
	}
}

/* less than or equal to 875px */

@media only screen and (max-width: 54.688em) {
	.mq875-hide,
	.js .js-mq875-hide {
		display: none;
		visibility: hidden;
	}

	.mq875-show,
	.js .js-mq875-show {
		display: block;
		visibility: visible;
	}

	.mq875-show-inline,
	.js .js-mq875-show-inline {
		display: inline;
		visibility: visible;
	}

	.mq875-visually-show {
		clip: auto;
		height: auto;
		position: static !important;
		width: auto;
	}
}

/* less than or equal to 640px */

@media only screen and (max-width: 40em) {
	.mq640-hide,
	.js .js-mq640-hide {
		display: none;
		visibility: hidden;
	}

	.mq640-show,
	.js .js-mq640-show {
		display: block;
		visibility: visible;
	}

	.mq640-show-inline,
	.js .js-mq640-show-inline {
		display: inline;
		visibility: visible;
	}

	.mq640-visually-show {
		clip: auto;
		height: auto;
		position: static !important;
		width: auto;
	}
}

/* less than or equal to 480px */

@media only screen and (max-width: 30em) {
	.mq480-hide,
	.js .js-mq480-hide {
		display: none;
		visibility: hidden;
	}

	.mq480-show,
	.js .js-mq480-show {
		display: block;
		visibility: visible;
	}

	.mq480-show-inline,
	html.js .js-mq480-show-inline {
		display: inline !important;
		visibility: visible !important;
	}

	.mq480-visually-show {
		clip: auto;
		height: auto;
		position: static !important;
		width: auto;
	}

	.js .js-smaller-author-etal {
		display: none;
		visibility: hidden;
	}

	.js .js-authors-expanded .js-smaller-author-etal {
		display: inline;
		visibility: visible;
	}
}
